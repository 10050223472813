import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  // Change enable to dev so can be test, previously production
  nuxtApp.vueApp.use(createGtm({
    id: config.public.GTM_ID,
    defer: true,
    compatibility: false,
    enabled: config.public.ENV === 'production',
    debug: config.public.ENV === 'development',
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  }))
})
