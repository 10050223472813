<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import type { MenuLinks } from '~/models/Menu'
import { useUser } from '~/store/user'
import locales from '~/assets/data/locales.json'

interface Props {
  hideMenus?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  hideMenus: false,
})

const config = useRuntimeConfig()
const { t, locale } = useI18n()
const route = useRoute()
const router = useRouter()
const user = useUser()
const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isLangModalOpen = useCookie('isLangModalOpen', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isClient = process.client
const isSearchMobile = ref(false)
const { mobile } = generateMenu()
const menuListMobile = shallowRef(mobile.value)
const openNavbar = ref('feature')
const langRedirect = ref()
const accept = async (close: () => void) => close()
const featureLinks = [
  { title: 'Job Recommendation', url: '/page/job-recommendation' },
  { title: 'Salary Report', url: '/page/salary-report' },
  { title: 'Enhance Resume', url: '/page/enhance-resume' },
]
const appConfig = useAppConfig()
if (appConfig.ugcFeature && token.value)
  await user.getRank()

watchEffect(() => {
  if (token.value || locale) {
    const { mobile } = generateMenu()
    menuListMobile.value = mobile.value
  }
})

function generateMenu() {
  const mobile = shallowRef<MenuLinks[]>([])

  if (token.value) {
    mobile.value = [
      { title: 'Dashboard', url: '/dashboard', name: 'dashboard', icon: 'heroicons:home' },
      { title: 'Enhance Resume', url: '/dashboard/enhance-resume', name: 'enhance-resume', icon: 'heroicons:paint-brush' },
      { title: t('nav_new_recommendation'), url: '/dashboard/job-recommendation/fetch', name: 'null', icon: 'heroicons:briefcase' },
      { title: 'Salary Report', url: '/dashboard/salary-report', name: 'salary-report', icon: 'heroicons:currency-dollar' },
      // { title: '', url: '', name: 'border', icon: '' },
      { title: t('nav_settings'), url: '/settings', name: 'settings', icon: 'heroicons:cog-6-tooth' },
    ]
  }
  return { mobile }
}
const featureLinkUrls = computed(() => featureLinks.map(item => item.url))

async function logoutClick() {
  useSetTracking('logout')
  await useSleep(1000)
  accept(close)
}

function setLangRedirect(event: any, redirectVal: string) {
  event.preventDefault()
  if (isEmpty(useGetSubdomain()))
    langRedirect.value = redirectVal
  else
    router.push(redirectVal)
}
</script>

<template>
  <div class="sticky top-0 z-30">
    <BaseOrganismsLanguageModal :redirect-to="langRedirect" @update:langRedirect="val => langRedirect = val" />
    <!-- CHOOSE REGION -->
    <div v-if="!props.hideMenus">
      <BaseOrganismsJavascriptModal v-show="!isClient" />
    </div>

    <!-- NAVBAR -->
    <Popover v-slot="{ open, close }" as="header" class="bg-white shadow relative">
      <div class="w-full max-w-6xl lg:max-w-7xl xl:max-w-9xl py-2 px-4 md:px-2 lg:p-6 flex items-center mx-auto h-17 lg:h-20">
        <!-- LEFT SECTION -->
        <div class="flex items-center w-full max-w-14 sm:max-w-28 md:max-w-36 lg:max-w-90 xl:max-w-100 h-full">
          <!-- ICON SECTION -->
          <NuxtLink to="/" class="flex-shrink-0 flex items-center w-14 sm:w-28 md:w-36 xl:w-42 h-full">
            <img class="w-full hidden sm:block" src="/images/logo-lg.png" alt="logo">
            <img class="w-full block sm:hidden" src="/images/logo-sm.png" alt="logo">
          </NuxtLink>

          <!-- MENU SECTION -->
          <!-- hideMenus used in onboarding layout -->
          <div v-if="!props.hideMenus" class="hidden lg:grid grid-cols-2 gap-x-4 w-full xl:w-[50%] h-22 text-sm text-gray-600 ml-auto">
            <!-- hide features dropdown if user logged in -->
            <div v-if="!token" class="group relative inline-flex items-center border-b-2 border-transparent h-21" :class="[featureLinkUrls.includes($route.path) && '!border-primary-500 font-bold text-gray-900']">
              <div class="flex items-center mx-auto cursor-pointer">
                Features
                <div class="flex-shrink-0 ml-2">
                  <Icon name="heroicons:chevron-up-20-solid" class="w-5 h-5 !hidden !group-hover:block" />
                  <Icon name="heroicons:chevron-down-20-solid" class="w-5 h-5 !group-hover:hidden" />
                </div>
              </div>
              <div class="hidden group-hover:block absolute z-50 mt-8 top-13 shadow-lg border border-gray-200 py-4 bg-white focus:outline-none">
                <a v-for="(item, idx) in featureLinks" :key="idx" class="flex font-normal px-3 py-3 items-center cursor-pointer hover:bg-primary-50 whitespace-nowrap" :class="[$route.path.includes(item.url) && 'bg-primary-50 text-gray-900']" @click="setLangRedirect($event, item.url)">
                  {{ item.title }}
                </a>
              </div>
            </div>

            <NuxtLink v-if="!isEmpty(token)" to="/dashboard" class="group relative inline-flex items-center border-b-2 border-transparent mx-auto h-21" :class="[$route.path.includes('dashboard') && '!border-primary-500 font-bold text-gray-900']">
              Dashboard
            </NuxtLink>

            <a href="/career-guide" class="group relative inline-flex items-center border-b-2 border-transparent mx-auto h-21" :class="[$route.path.includes('career-guide') && '!border-primary-500 font-bold text-gray-900']" @click="setLangRedirect($event, '/career-guide')">
              Career Guide
            </a>
          </div>
        </div>

        <!-- MIDDLE SECTION -->
        <!-- hideMenus used in onboarding layout -->
        <div v-if="!props.hideMenus" class="w-full flex mx-2">
          <BaseOrganismsSearchBar class="w-full lg:max-w-lg mr-2 lg:mx-auto" @update:searchbar="item => isSearchMobile = item" />
        </div>

        <!-- RIGHT SECTION -->
        <!-- hideMenus used in onboarding layout -->
        <div v-if="!props.hideMenus" :class="`${token ? 'max-w-10' : 'max-w-17'} flex items-center h-full w-full lg:max-w-sm`">
          <PopoverButton v-if="token" class="lg:mr-4 relative bg-white rounded-full flex text-sm ml-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <div class="h-8 w-8 lg:h-9 lg:w-9 rounded-full bg-cyan-100 leading-9 lg:leading-11 flex items-center">
              <span class="text-cyan-500 font-semibold mx-auto">{{ user.parsed.name ? user.parsed.name.trim().split(' ').map(word => word.slice(0, 2).toUpperCase()).join('').slice(0, 2) : 'A' }}</span>
            </div>
          </PopoverButton>

          <!-- DESKTOP REGISTER LOGIN BUTTON lg:grid grid-cols-2 gap-x-4 -->
          <div v-if="!token" class="hidden lg:flex items-center space-x-4 w-full">
            <BaseAtomsButton size="sm" to="/continue" outline class="font-semibold min-w-[120px]">
              Register
            </BaseAtomsButton>
            <BaseAtomsButton size="sm" to="/continue" class="font-semibold min-w-[120px]">
              Login
            </BaseAtomsButton>
            <div data-v-48b00754="" class="w-[1px] h-6 bg-gray-900" />
            <a href="https://epicareer.com/employer" target="_blank" class="text-gray-900 font-semibold">For Employer</a>
          </div>

          <!-- MOBILE MENU BUTTON -->
          <PopoverButton v-if="!token" class="items-center flex lg:hidden py-2 px-4 text-xs font-bold rounded-lg border-1 border-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 text-gray-900">
            <span class="sr-only">Open main menu</span>
            Menu
          </PopoverButton>
        </div>
      </div>

      <!-- MENUS DROPDOWN -->
      <TransitionRoot as="template" :show="open">
        <div class="md:relative">
          <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-150 ease-in" leave-from="opacity-100" leave-to="opacity-0">
            <PopoverOverlay class="z-40 fixed inset-0 bg-black bg-opacity-25 lg:hidden" />
          </TransitionChild>

          <TransitionChild as="template" enter="duration-150 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-150 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <PopoverPanel focus class="z-50 absolute <lg:w-full lg:w-[360px] lg:right-[10%]">
              <div class="shadow-lg rounded-md ring-0 bg-white ml-auto">
                <div v-if="token">
                  <div class="flex justify-between px-6 py-4">
                    <LazyBaseOrganismsMainNavbarUserInfo v-if="token" @click="accept(close)" />
                    <div class="-mr-2">
                      <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                        <span class="sr-only">Close menu</span>
                        <Icon name="heroicons:x-mark" class="h-6 w-6" aria-hidden="true" />
                      </PopoverButton>
                    </div>
                  </div>
                  <div class="border-t !border-gray-200 my-2" />
                </div>
                <div v-else>
                  <div class="text-xl font-bold leading-6 text-gray-900 flex px-6 py-2 flex items-center">
                    <NuxtLink to="/" class="flex-shrink-0 flex items-center w-14 h-14 mr-2 lg:mr-0 sm:w-30">
                      <img class="w-full hidden sm:block w-30" src="/images/logo-lg.png" alt="logo">
                      <img class="w-full block sm:hidden w-14" src="/images/logo-sm.png" alt="logo">
                    </NuxtLink>
                    <Icon name="heroicons:x-mark" class="w-6 h-6 ml-auto mt-1 cursor-pointer" @click="accept(close)" />
                  </div>
                  <div class="border-t !border-gray-200" />
                </div>

                <div class="text-gray-900">
                  <div v-if="token">
                    <NuxtLink
                      v-for="(item, idx) in menuListMobile" :key="idx" :to="item.url"
                      :class="[
                        {
                          'bg-indigo-100 hover:bg-indigo-100 text-indigo-600 font-semibold': item.url === $route.path,
                          'border-b !border-gray-200 !rounded-none !py-0 !my-2 px-0': (item?.type && item.type) === 'divider',
                        },
                        item.class,
                        `${item.name !== 'border' ? 'px-6 py-4 hover:bg-gray-100' : ''} block text-sm font-medium text-gray-700 mb-2`,
                      ]"
                      :target="item.target" @click="accept(close)"
                    >
                      <div v-if="item.name !== 'border'">
                        <Icon v-if="!!item.icon" :name="item.icon" class="h-6 w-6 mr-4" style="vertical-align:-25%" /> {{ item.title }}
                      </div>

                      <div v-else class="border-t !border-gray-200" />
                    </NuxtLink>
                  </div>

                  <!-- FEATURES DROPDOWN -->
                  <div v-if="!token" class="block lg:hidden">
                    <Disclosure>
                      <DisclosureButton class="px-6 py-4 flex items-center text-sm hover:bg-gray-100 font-medium w-full focus:ring-0 focus:outline-none" @click="openNavbar = openNavbar === 'feature' ? null : 'feature'">
                        Features
                        <span class="ml-2 flex-1 text-right">
                          <Icon v-if="openNavbar === 'feature'" name="heroicons:chevron-up-20-solid" class="w-5 h-5" />
                          <Icon v-else name="heroicons:chevron-down-20-solid" class="w-5 h-5" />
                        </span>
                      </DisclosureButton>
                      <div v-show="openNavbar === 'feature'">
                        <DisclosurePanel static>
                          <a v-for="(item, idx) in featureLinks" :key="idx" :class="[route.path.includes(item.url) && 'bg-indigo-100 hover:bg-indigo-100 text-indigo-600 font-semibold']" class="cursor-pointer block text-sm px-6 py-4 pl-8 hover:bg-gray-100 font-medium flex items-center" @click="setLangRedirect($event, item.url); accept(close)">
                            {{ item.title }}
                          </a>
                        </DisclosurePanel>
                      </div>
                    </Disclosure>
                  </div>

                  <div class="blog lg:hidden">
                    <div class="border-t !border-gray-200 my-2" />
                    <div class="flex items-center text-sm px-6 py-4 hover:bg-gray-100 font-medium w-full">
                      <a href="/career-guide" @click="setLangRedirect($event, '/career-guide')">Career Guide</a>
                    </div>
                    <div class="border-t !border-gray-200 my-2" />
                    <div class="flex items-center text-sm px-6 py-4 hover:bg-gray-100 font-medium w-full">
                      <a href="https://epicareer.com/employer" target="_blank">For Employer</a>
                    </div>

                    <div class="border-t !border-gray-200 my-2" />
                    <div :class="`${!isEmpty(useGetSubdomain()) ? 'font-medium' : 'text-[#5843e5] font-semibold'} flex items-center text-sm px-6 py-4 hover:bg-gray-100 w-full cursor-pointer`" @click="isLangModalOpen = 'true'">
                      <Icon :name="!isEmpty(useGetSubdomain()) ? locales.find(obj => obj.region === useGetSubdomain())?.icon : 'material-symbols:language'" class="w-5 h-5 mr-2" />
                      {{ !isEmpty(useGetSubdomain()) ? `Region : ${locales.find(obj => obj.region === useGetSubdomain())?.label}` : 'Choose Region' }}
                    </div>
                  </div>

                  <div class="border-t !border-gray-200 my-2" />
                  <div v-if="!token" class="pt-4 px-3 pb-6">
                    <BaseAtomsButton size="sm" to="/continue" class="font-semibold w-full" @click="accept(close)">
                      Sign In or Register
                    </BaseAtomsButton>
                  </div>
                  <NuxtLink v-else to="/logout" class="block text-sm p-6 pt-4 font-medium text-gray-700 text-red-500 hover:text-red-500" @click="logoutClick()">
                    <Icon name="heroicons:arrow-right-on-rectangle-solid" class="h-5 w-5 mr-1" style="vertical-align:-25%" /> Logout
                  </NuxtLink>
                </div>
              </div>
            </PopoverPanel>
          </TransitionChild>
        </div>
      </TransitionRoot>
    </Popover>
  </div>
</template>
