export function useTextToParam(value: string | string[], revert: boolean) {
  // revert false : Software Developer turn into software-developer
  // revert true : software-developer turn into software developer
  if (value) {
    if (revert)
      return value.toString().replaceAll('-', ' ').replaceAll('-', ',')
    else
      return value.toString().replaceAll(' ', '-').replaceAll(',', '-').replaceAll('--', '-')
  }
}
