const useSearchApi = async <T>(url: string | (() => string), options?: object) => {
  const { $config } = useNuxtApp()
  const controller = new AbortController()
  const signal = controller.signal
  const timeout = setTimeout(() => controller.abort(), $config.public.API_TIMEOUT)
  return useFetch<T>(url, {
    baseURL: $config.public.SEARCHAPI_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
    async onRequestError({ error }) {
      clearTimeout(timeout)
      throw error
    },
    async onResponse() {
      clearTimeout(timeout)
    },
    async onResponseError({ request, response }) {
      clearTimeout(timeout)
      console.error('[fetch response error]', request, response.status, response.body)
    },
    ...options,
  })
}

export default useSearchApi
