<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import clone from 'just-clone'
import locales from '~/assets/data/locales.json'

const config = useRuntimeConfig()
const route = useRoute()
const region = useCookie('region', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isClient = process.client
const langOpt = ref(clone(locales))
langOpt.value.unshift({ label: 'Select Region', icon: '', region: '', lang: '', hrefLang: '' })

function switchLocale(langCode: string) {
  region.value = langCode

  // check if user on search page, if yes then redirect to requested region + reset location query
  // if user not on search page, just redirect to that page without changing any query
  const modifiedUrl = new URL(route.fullPath, useSetSubdomain(langCode))
  if (route.path === '/search')
    modifiedUrl.searchParams.set('location', 'all')
  window.location.href = modifiedUrl.href
  scrollTo(0, 0)
}
</script>

<template>
  <div class="flex">
    <Menu v-show="isClient" v-slot="{ open }" as="div" class="relative items-center inline-flex my-auto">
      <MenuButton class="text-sm ml-0 md:ml-auto font-medium hover:text-gray-700 text-gray-500 inline-flex items-center">
        <span class="inline-block mr-1 text-gray-900">{{ langOpt.find(item => item.region === region)?.label || langOpt[0]?.label }}</span>
        <Icon v-if="open" name="heroicons:chevron-up" class="w-5 h-5 inline-block text-gray-900" />
        <Icon v-else name="heroicons:chevron-down" class="w-5 h-5 inline-block text-gray-900" />
      </MenuButton>
      <transition v-show="open" enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems static class="origin-top-right absolute z-50 right-0 w-full lg:w-64 bottom-[30px] mt-2 rounded-md border border-gray-200 shadow-lg py-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem v-for="(item, idx) in langOpt" v-slot="{ active }" :key="idx">
            <div :class="[active || region === item.region ? 'bg-gray-100' : '']" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" @click="switchLocale(item.region)">
              {{ item.label }}
            </div>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
    <div v-if="!isClient" class="relative my-auto flex items-center space-x-2 bg-gray-50 py-2 px-3 rounded-md">
      <span class="text-xs font-bold whitespace-nowrap">Select Region :</span>
      <a v-for="(item, idx) in langOpt" :key="idx" :class="`${idx === 0 ? 'hidden' : ''} ${useGetSubdomain() === item.region ? 'border-primary m-auto' : ''} border border-2 rounded-md px-1 border-transparent`" :href="useSetSubdomain(item.region)">
        <Icon :name="item.icon" class="w-5 h-5" />
      </a>
    </div>
  </div>
</template>

<style scoped>
.border-primary{
  border-color: #5843E5
}
</style>
