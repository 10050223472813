import { defineStore } from 'pinia'

interface Count {
  initial: Number
  end: Number
}

interface StatisticState {
  job: Count
  visitor: Count
  apply: Count
}

export const useStatistic = defineStore('statistic', {
  state: (): StatisticState => ({
    job: { initial: 0, end: 0 } as Count,
    visitor: { initial: 0, end: 0 } as Count,
    apply: { initial: 0, end: 0 } as Count,
  }),
  actions: {
    async fetchJobCount() {
      const { data, error } = await useSearchApi('/jobs/count', { lazy: true, server: false, key: 'stat_job_count', initialCache: false })
      if (!error.value) {
        watch(data, (newData) => {
          const resp: { total?: number | null } = newData
          this.job.initial = this.job.end || 0
          this.job.end = resp?.total || 0
        })
      }
    },
    async fetchApplyCount() {
      const { data, error } = await useResumeApi('/analytics?category=job&action=apply', {
        useApiKey: true,
        lazy: true,
        server: false,
        key: 'stat_apply_count',
        initialCache: false,
      })
      if (!error.value) {
        watch(data, (newData) => {
          const resp: { data?: { uniqueEvents: string } } = newData
          if (resp?.data?.uniqueEvents) {
            this.apply.initial = this.apply.end || 0
            this.apply.end = Number(resp.data.uniqueEvents) || 0
          }
        })
      }
    },

  },
  persist: {
    key: 'statistic',
    storage: process.client ? window.localStorage : null,
  },
})
