import { default as aboutxZn5ZC3kmiMeta } from "/builds/epicareer/epicareer-website/pages/about.vue?macro=true";
import { default as googleoJTh4PfCQgMeta } from "/builds/epicareer/epicareer-website/pages/callback/google.vue?macro=true";
import { default as linkedinNZcAKV0uGxMeta } from "/builds/epicareer/epicareer-website/pages/callback/linkedin.vue?macro=true";
import { default as _91id_93_45_91slug_93B41guqxaUWMeta } from "/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue?macro=true";
import { default as _91slug_93jMTWwCxxJIMeta } from "/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue?macro=true";
import { default as indexgqadFpkWqXMeta } from "/builds/epicareer/epicareer-website/pages/career-guide/index.vue?macro=true";
import { default as indexbEt2oo02IbMeta } from "/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue?macro=true";
import { default as jobs_45vacancyUpzFtqmwpKMeta } from "/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue?macro=true";
import { default as salaries_45benefitsKjta6dzWesMeta } from "/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue?macro=true";
import { default as videos_45photosxSMdXK78PJMeta } from "/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue?macro=true";
import { default as _91slug_93d31nPZF9Y1Meta } from "/builds/epicareer/epicareer-website/pages/companies/[slug].vue?macro=true";
import { default as indexeLYj8ViAViMeta } from "/builds/epicareer/epicareer-website/pages/companies/all/index.vue?macro=true";
import { default as starting_45with_45_91key_93bDIgpXyrCeMeta } from "/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue?macro=true";
import { default as contact2eEeW7EYNKMeta } from "/builds/epicareer/epicareer-website/pages/contact.vue?macro=true";
import { default as indexv4stYbzQNQMeta } from "/builds/epicareer/epicareer-website/pages/continue/index.vue?macro=true";
import { default as verifysE7xuyqv7ZMeta } from "/builds/epicareer/epicareer-website/pages/continue/verify.vue?macro=true";
import { default as enhance_45resumeTdPqbEiFZ2Meta } from "/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue?macro=true";
import { default as indextqQw1jjsklMeta } from "/builds/epicareer/epicareer-website/pages/dashboard/index.vue?macro=true";
import { default as _91uid_93D6FBCc60AtMeta } from "/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue?macro=true";
import { default as fetchtdFVRKBKaiMeta } from "/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue?macro=true";
import { default as filteruBcutmYY8yMeta } from "/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue?macro=true";
import { default as indextCds34ago5Meta } from "/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue?macro=true";
import { default as salary_45reportfzAX4In9sOMeta } from "/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue?macro=true";
import { default as delete_45accountgAA5ieHzL9Meta } from "/builds/epicareer/epicareer-website/pages/delete-account.vue?macro=true";
import { default as resumeERqHRvdWy7Meta } from "/builds/epicareer/epicareer-website/pages/download/resume.vue?macro=true";
import { default as _91code_93yaDQ6XixDAMeta } from "/builds/epicareer/epicareer-website/pages/errors/[code].vue?macro=true";
import { default as timeoutotkicAMZnJMeta } from "/builds/epicareer/epicareer-website/pages/errors/timeout.vue?macro=true";
import { default as faqpi4QqDZFKnMeta } from "/builds/epicareer/epicareer-website/pages/faq.vue?macro=true";
import { default as forgot_45passwordgnThb6A7m5Meta } from "/builds/epicareer/epicareer-website/pages/forgot-password.vue?macro=true";
import { default as indexe3X76aWWI3Meta } from "/builds/epicareer/epicareer-website/pages/index.vue?macro=true";
import { default as _91id_93_45_91slug_93JkZKlXxGiXMeta } from "/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue?macro=true";
import { default as indexF0us1CI08lMeta } from "/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue?macro=true";
import { default as indextjFg1OdSskMeta } from "/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue?macro=true";
import { default as by_45companyrVdpd5locyMeta } from "/builds/epicareer/epicareer-website/pages/jobs/by-company.vue?macro=true";
import { default as index8bOdJonu9SMeta } from "/builds/epicareer/epicareer-website/pages/jobs/index.vue?macro=true";
import { default as logoutb7oC3mZKxFMeta } from "/builds/epicareer/epicareer-website/pages/logout.vue?macro=true";
import { default as unsubscribeP9Lsp4qDSfMeta } from "/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue?macro=true";
import { default as indexYv5hEmbXfHMeta } from "/builds/epicareer/epicareer-website/pages/onboard/index.vue?macro=true";
import { default as _91uid_93StOH4YwNfcMeta } from "/builds/epicareer/epicareer-website/pages/page/[uid].vue?macro=true";
import { default as privacy_45policy7hhikZVQWCMeta } from "/builds/epicareer/epicareer-website/pages/privacy-policy.vue?macro=true";
import { default as apply6mzx5S65CnMeta } from "/builds/epicareer/epicareer-website/pages/redirect/apply.vue?macro=true";
import { default as _91location_93HZOAJMezhoMeta } from "/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue?macro=true";
import { default as _91_46_46_46search_938A2XZUsueOMeta } from "/builds/epicareer/epicareer-website/pages/search/[...search].vue?macro=true";
import { default as settingsvjiEC3KAb9Meta } from "/builds/epicareer/epicareer-website/pages/settings.vue?macro=true";
import { default as terms_45of_45serviceOijF35eM4TMeta } from "/builds/epicareer/epicareer-website/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: aboutxZn5ZC3kmiMeta?.name ?? "about___us",
    path: aboutxZn5ZC3kmiMeta?.path ?? "/about",
    meta: aboutxZn5ZC3kmiMeta || {},
    alias: aboutxZn5ZC3kmiMeta?.alias || [],
    redirect: aboutxZn5ZC3kmiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutxZn5ZC3kmiMeta?.name ?? "about___gb",
    path: aboutxZn5ZC3kmiMeta?.path ?? "/gb/about",
    meta: aboutxZn5ZC3kmiMeta || {},
    alias: aboutxZn5ZC3kmiMeta?.alias || [],
    redirect: aboutxZn5ZC3kmiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutxZn5ZC3kmiMeta?.name ?? "about___sg",
    path: aboutxZn5ZC3kmiMeta?.path ?? "/sg/about",
    meta: aboutxZn5ZC3kmiMeta || {},
    alias: aboutxZn5ZC3kmiMeta?.alias || [],
    redirect: aboutxZn5ZC3kmiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutxZn5ZC3kmiMeta?.name ?? "about___my",
    path: aboutxZn5ZC3kmiMeta?.path ?? "/my/about",
    meta: aboutxZn5ZC3kmiMeta || {},
    alias: aboutxZn5ZC3kmiMeta?.alias || [],
    redirect: aboutxZn5ZC3kmiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutxZn5ZC3kmiMeta?.name ?? "about___id",
    path: aboutxZn5ZC3kmiMeta?.path ?? "/id/about",
    meta: aboutxZn5ZC3kmiMeta || {},
    alias: aboutxZn5ZC3kmiMeta?.alias || [],
    redirect: aboutxZn5ZC3kmiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutxZn5ZC3kmiMeta?.name ?? "about___ph",
    path: aboutxZn5ZC3kmiMeta?.path ?? "/ph/about",
    meta: aboutxZn5ZC3kmiMeta || {},
    alias: aboutxZn5ZC3kmiMeta?.alias || [],
    redirect: aboutxZn5ZC3kmiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: googleoJTh4PfCQgMeta?.name ?? "callback-google___us",
    path: googleoJTh4PfCQgMeta?.path ?? "/callback/google",
    meta: googleoJTh4PfCQgMeta || {},
    alias: googleoJTh4PfCQgMeta?.alias || [],
    redirect: googleoJTh4PfCQgMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/google.vue").then(m => m.default || m)
  },
  {
    name: googleoJTh4PfCQgMeta?.name ?? "callback-google___gb",
    path: googleoJTh4PfCQgMeta?.path ?? "/gb/callback/google",
    meta: googleoJTh4PfCQgMeta || {},
    alias: googleoJTh4PfCQgMeta?.alias || [],
    redirect: googleoJTh4PfCQgMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/google.vue").then(m => m.default || m)
  },
  {
    name: googleoJTh4PfCQgMeta?.name ?? "callback-google___sg",
    path: googleoJTh4PfCQgMeta?.path ?? "/sg/callback/google",
    meta: googleoJTh4PfCQgMeta || {},
    alias: googleoJTh4PfCQgMeta?.alias || [],
    redirect: googleoJTh4PfCQgMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/google.vue").then(m => m.default || m)
  },
  {
    name: googleoJTh4PfCQgMeta?.name ?? "callback-google___my",
    path: googleoJTh4PfCQgMeta?.path ?? "/my/callback/google",
    meta: googleoJTh4PfCQgMeta || {},
    alias: googleoJTh4PfCQgMeta?.alias || [],
    redirect: googleoJTh4PfCQgMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/google.vue").then(m => m.default || m)
  },
  {
    name: googleoJTh4PfCQgMeta?.name ?? "callback-google___id",
    path: googleoJTh4PfCQgMeta?.path ?? "/id/callback/google",
    meta: googleoJTh4PfCQgMeta || {},
    alias: googleoJTh4PfCQgMeta?.alias || [],
    redirect: googleoJTh4PfCQgMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/google.vue").then(m => m.default || m)
  },
  {
    name: googleoJTh4PfCQgMeta?.name ?? "callback-google___ph",
    path: googleoJTh4PfCQgMeta?.path ?? "/ph/callback/google",
    meta: googleoJTh4PfCQgMeta || {},
    alias: googleoJTh4PfCQgMeta?.alias || [],
    redirect: googleoJTh4PfCQgMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/google.vue").then(m => m.default || m)
  },
  {
    name: linkedinNZcAKV0uGxMeta?.name ?? "callback-linkedin___us",
    path: linkedinNZcAKV0uGxMeta?.path ?? "/callback/linkedin",
    meta: linkedinNZcAKV0uGxMeta || {},
    alias: linkedinNZcAKV0uGxMeta?.alias || [],
    redirect: linkedinNZcAKV0uGxMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/linkedin.vue").then(m => m.default || m)
  },
  {
    name: linkedinNZcAKV0uGxMeta?.name ?? "callback-linkedin___gb",
    path: linkedinNZcAKV0uGxMeta?.path ?? "/gb/callback/linkedin",
    meta: linkedinNZcAKV0uGxMeta || {},
    alias: linkedinNZcAKV0uGxMeta?.alias || [],
    redirect: linkedinNZcAKV0uGxMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/linkedin.vue").then(m => m.default || m)
  },
  {
    name: linkedinNZcAKV0uGxMeta?.name ?? "callback-linkedin___sg",
    path: linkedinNZcAKV0uGxMeta?.path ?? "/sg/callback/linkedin",
    meta: linkedinNZcAKV0uGxMeta || {},
    alias: linkedinNZcAKV0uGxMeta?.alias || [],
    redirect: linkedinNZcAKV0uGxMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/linkedin.vue").then(m => m.default || m)
  },
  {
    name: linkedinNZcAKV0uGxMeta?.name ?? "callback-linkedin___my",
    path: linkedinNZcAKV0uGxMeta?.path ?? "/my/callback/linkedin",
    meta: linkedinNZcAKV0uGxMeta || {},
    alias: linkedinNZcAKV0uGxMeta?.alias || [],
    redirect: linkedinNZcAKV0uGxMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/linkedin.vue").then(m => m.default || m)
  },
  {
    name: linkedinNZcAKV0uGxMeta?.name ?? "callback-linkedin___id",
    path: linkedinNZcAKV0uGxMeta?.path ?? "/id/callback/linkedin",
    meta: linkedinNZcAKV0uGxMeta || {},
    alias: linkedinNZcAKV0uGxMeta?.alias || [],
    redirect: linkedinNZcAKV0uGxMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/linkedin.vue").then(m => m.default || m)
  },
  {
    name: linkedinNZcAKV0uGxMeta?.name ?? "callback-linkedin___ph",
    path: linkedinNZcAKV0uGxMeta?.path ?? "/ph/callback/linkedin",
    meta: linkedinNZcAKV0uGxMeta || {},
    alias: linkedinNZcAKV0uGxMeta?.alias || [],
    redirect: linkedinNZcAKV0uGxMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/callback/linkedin.vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93B41guqxaUWMeta?.name ?? "career-guide-id-slug___us",
    path: _91id_93_45_91slug_93B41guqxaUWMeta?.path ?? "/career-guide/:id()-:slug()",
    meta: _91id_93_45_91slug_93B41guqxaUWMeta || {},
    alias: _91id_93_45_91slug_93B41guqxaUWMeta?.alias || [],
    redirect: _91id_93_45_91slug_93B41guqxaUWMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93B41guqxaUWMeta?.name ?? "career-guide-id-slug___gb",
    path: _91id_93_45_91slug_93B41guqxaUWMeta?.path ?? "/gb/career-guide/:id()-:slug()",
    meta: _91id_93_45_91slug_93B41guqxaUWMeta || {},
    alias: _91id_93_45_91slug_93B41guqxaUWMeta?.alias || [],
    redirect: _91id_93_45_91slug_93B41guqxaUWMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93B41guqxaUWMeta?.name ?? "career-guide-id-slug___sg",
    path: _91id_93_45_91slug_93B41guqxaUWMeta?.path ?? "/sg/career-guide/:id()-:slug()",
    meta: _91id_93_45_91slug_93B41guqxaUWMeta || {},
    alias: _91id_93_45_91slug_93B41guqxaUWMeta?.alias || [],
    redirect: _91id_93_45_91slug_93B41guqxaUWMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93B41guqxaUWMeta?.name ?? "career-guide-id-slug___my",
    path: _91id_93_45_91slug_93B41guqxaUWMeta?.path ?? "/my/career-guide/:id()-:slug()",
    meta: _91id_93_45_91slug_93B41guqxaUWMeta || {},
    alias: _91id_93_45_91slug_93B41guqxaUWMeta?.alias || [],
    redirect: _91id_93_45_91slug_93B41guqxaUWMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93B41guqxaUWMeta?.name ?? "career-guide-id-slug___id",
    path: _91id_93_45_91slug_93B41guqxaUWMeta?.path ?? "/id/career-guide/:id()-:slug()",
    meta: _91id_93_45_91slug_93B41guqxaUWMeta || {},
    alias: _91id_93_45_91slug_93B41guqxaUWMeta?.alias || [],
    redirect: _91id_93_45_91slug_93B41guqxaUWMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93B41guqxaUWMeta?.name ?? "career-guide-id-slug___ph",
    path: _91id_93_45_91slug_93B41guqxaUWMeta?.path ?? "/ph/career-guide/:id()-:slug()",
    meta: _91id_93_45_91slug_93B41guqxaUWMeta || {},
    alias: _91id_93_45_91slug_93B41guqxaUWMeta?.alias || [],
    redirect: _91id_93_45_91slug_93B41guqxaUWMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMTWwCxxJIMeta?.name ?? "career-guide-author-slug___us",
    path: _91slug_93jMTWwCxxJIMeta?.path ?? "/career-guide/author/:slug()",
    meta: _91slug_93jMTWwCxxJIMeta || {},
    alias: _91slug_93jMTWwCxxJIMeta?.alias || [],
    redirect: _91slug_93jMTWwCxxJIMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMTWwCxxJIMeta?.name ?? "career-guide-author-slug___gb",
    path: _91slug_93jMTWwCxxJIMeta?.path ?? "/gb/career-guide/author/:slug()",
    meta: _91slug_93jMTWwCxxJIMeta || {},
    alias: _91slug_93jMTWwCxxJIMeta?.alias || [],
    redirect: _91slug_93jMTWwCxxJIMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMTWwCxxJIMeta?.name ?? "career-guide-author-slug___sg",
    path: _91slug_93jMTWwCxxJIMeta?.path ?? "/sg/career-guide/author/:slug()",
    meta: _91slug_93jMTWwCxxJIMeta || {},
    alias: _91slug_93jMTWwCxxJIMeta?.alias || [],
    redirect: _91slug_93jMTWwCxxJIMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMTWwCxxJIMeta?.name ?? "career-guide-author-slug___my",
    path: _91slug_93jMTWwCxxJIMeta?.path ?? "/my/career-guide/author/:slug()",
    meta: _91slug_93jMTWwCxxJIMeta || {},
    alias: _91slug_93jMTWwCxxJIMeta?.alias || [],
    redirect: _91slug_93jMTWwCxxJIMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMTWwCxxJIMeta?.name ?? "career-guide-author-slug___id",
    path: _91slug_93jMTWwCxxJIMeta?.path ?? "/id/career-guide/author/:slug()",
    meta: _91slug_93jMTWwCxxJIMeta || {},
    alias: _91slug_93jMTWwCxxJIMeta?.alias || [],
    redirect: _91slug_93jMTWwCxxJIMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMTWwCxxJIMeta?.name ?? "career-guide-author-slug___ph",
    path: _91slug_93jMTWwCxxJIMeta?.path ?? "/ph/career-guide/author/:slug()",
    meta: _91slug_93jMTWwCxxJIMeta || {},
    alias: _91slug_93jMTWwCxxJIMeta?.alias || [],
    redirect: _91slug_93jMTWwCxxJIMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexgqadFpkWqXMeta?.name ?? "career-guide___us",
    path: indexgqadFpkWqXMeta?.path ?? "/career-guide",
    meta: indexgqadFpkWqXMeta || {},
    alias: indexgqadFpkWqXMeta?.alias || [],
    redirect: indexgqadFpkWqXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexgqadFpkWqXMeta?.name ?? "career-guide___gb",
    path: indexgqadFpkWqXMeta?.path ?? "/gb/career-guide",
    meta: indexgqadFpkWqXMeta || {},
    alias: indexgqadFpkWqXMeta?.alias || [],
    redirect: indexgqadFpkWqXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexgqadFpkWqXMeta?.name ?? "career-guide___sg",
    path: indexgqadFpkWqXMeta?.path ?? "/sg/career-guide",
    meta: indexgqadFpkWqXMeta || {},
    alias: indexgqadFpkWqXMeta?.alias || [],
    redirect: indexgqadFpkWqXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexgqadFpkWqXMeta?.name ?? "career-guide___my",
    path: indexgqadFpkWqXMeta?.path ?? "/my/career-guide",
    meta: indexgqadFpkWqXMeta || {},
    alias: indexgqadFpkWqXMeta?.alias || [],
    redirect: indexgqadFpkWqXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexgqadFpkWqXMeta?.name ?? "career-guide___id",
    path: indexgqadFpkWqXMeta?.path ?? "/id/career-guide",
    meta: indexgqadFpkWqXMeta || {},
    alias: indexgqadFpkWqXMeta?.alias || [],
    redirect: indexgqadFpkWqXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexgqadFpkWqXMeta?.name ?? "career-guide___ph",
    path: indexgqadFpkWqXMeta?.path ?? "/ph/career-guide",
    meta: indexgqadFpkWqXMeta || {},
    alias: indexgqadFpkWqXMeta?.alias || [],
    redirect: indexgqadFpkWqXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/career-guide/index.vue").then(m => m.default || m)
  },
  {
    path: _91slug_93d31nPZF9Y1Meta?.path ?? "/companies/:slug()",
    children: [
  {
    name: indexbEt2oo02IbMeta?.name ?? "companies-slug___us",
    path: indexbEt2oo02IbMeta?.path ?? "",
    meta: indexbEt2oo02IbMeta || {},
    alias: indexbEt2oo02IbMeta?.alias || [],
    redirect: indexbEt2oo02IbMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: jobs_45vacancyUpzFtqmwpKMeta?.name ?? "companies-slug-jobs-vacancy___us",
    path: jobs_45vacancyUpzFtqmwpKMeta?.path ?? "jobs-vacancy",
    meta: jobs_45vacancyUpzFtqmwpKMeta || {},
    alias: jobs_45vacancyUpzFtqmwpKMeta?.alias || [],
    redirect: jobs_45vacancyUpzFtqmwpKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue").then(m => m.default || m)
  },
  {
    name: salaries_45benefitsKjta6dzWesMeta?.name ?? "companies-slug-salaries-benefits___us",
    path: salaries_45benefitsKjta6dzWesMeta?.path ?? "salaries-benefits",
    meta: salaries_45benefitsKjta6dzWesMeta || {},
    alias: salaries_45benefitsKjta6dzWesMeta?.alias || [],
    redirect: salaries_45benefitsKjta6dzWesMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue").then(m => m.default || m)
  },
  {
    name: videos_45photosxSMdXK78PJMeta?.name ?? "companies-slug-videos-photos___us",
    path: videos_45photosxSMdXK78PJMeta?.path ?? "videos-photos",
    meta: videos_45photosxSMdXK78PJMeta || {},
    alias: videos_45photosxSMdXK78PJMeta?.alias || [],
    redirect: videos_45photosxSMdXK78PJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue").then(m => m.default || m)
  }
],
    name: _91slug_93d31nPZF9Y1Meta?.name ?? undefined,
    meta: _91slug_93d31nPZF9Y1Meta || {},
    alias: _91slug_93d31nPZF9Y1Meta?.alias || [],
    redirect: _91slug_93d31nPZF9Y1Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93d31nPZF9Y1Meta?.path ?? "/gb/companies/:slug()",
    children: [
  {
    name: indexbEt2oo02IbMeta?.name ?? "companies-slug___gb",
    path: indexbEt2oo02IbMeta?.path ?? "",
    meta: indexbEt2oo02IbMeta || {},
    alias: indexbEt2oo02IbMeta?.alias || [],
    redirect: indexbEt2oo02IbMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: jobs_45vacancyUpzFtqmwpKMeta?.name ?? "companies-slug-jobs-vacancy___gb",
    path: jobs_45vacancyUpzFtqmwpKMeta?.path ?? "jobs-vacancy",
    meta: jobs_45vacancyUpzFtqmwpKMeta || {},
    alias: jobs_45vacancyUpzFtqmwpKMeta?.alias || [],
    redirect: jobs_45vacancyUpzFtqmwpKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue").then(m => m.default || m)
  },
  {
    name: salaries_45benefitsKjta6dzWesMeta?.name ?? "companies-slug-salaries-benefits___gb",
    path: salaries_45benefitsKjta6dzWesMeta?.path ?? "salaries-benefits",
    meta: salaries_45benefitsKjta6dzWesMeta || {},
    alias: salaries_45benefitsKjta6dzWesMeta?.alias || [],
    redirect: salaries_45benefitsKjta6dzWesMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue").then(m => m.default || m)
  },
  {
    name: videos_45photosxSMdXK78PJMeta?.name ?? "companies-slug-videos-photos___gb",
    path: videos_45photosxSMdXK78PJMeta?.path ?? "videos-photos",
    meta: videos_45photosxSMdXK78PJMeta || {},
    alias: videos_45photosxSMdXK78PJMeta?.alias || [],
    redirect: videos_45photosxSMdXK78PJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue").then(m => m.default || m)
  }
],
    name: _91slug_93d31nPZF9Y1Meta?.name ?? undefined,
    meta: _91slug_93d31nPZF9Y1Meta || {},
    alias: _91slug_93d31nPZF9Y1Meta?.alias || [],
    redirect: _91slug_93d31nPZF9Y1Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93d31nPZF9Y1Meta?.path ?? "/sg/companies/:slug()",
    children: [
  {
    name: indexbEt2oo02IbMeta?.name ?? "companies-slug___sg",
    path: indexbEt2oo02IbMeta?.path ?? "",
    meta: indexbEt2oo02IbMeta || {},
    alias: indexbEt2oo02IbMeta?.alias || [],
    redirect: indexbEt2oo02IbMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: jobs_45vacancyUpzFtqmwpKMeta?.name ?? "companies-slug-jobs-vacancy___sg",
    path: jobs_45vacancyUpzFtqmwpKMeta?.path ?? "jobs-vacancy",
    meta: jobs_45vacancyUpzFtqmwpKMeta || {},
    alias: jobs_45vacancyUpzFtqmwpKMeta?.alias || [],
    redirect: jobs_45vacancyUpzFtqmwpKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue").then(m => m.default || m)
  },
  {
    name: salaries_45benefitsKjta6dzWesMeta?.name ?? "companies-slug-salaries-benefits___sg",
    path: salaries_45benefitsKjta6dzWesMeta?.path ?? "salaries-benefits",
    meta: salaries_45benefitsKjta6dzWesMeta || {},
    alias: salaries_45benefitsKjta6dzWesMeta?.alias || [],
    redirect: salaries_45benefitsKjta6dzWesMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue").then(m => m.default || m)
  },
  {
    name: videos_45photosxSMdXK78PJMeta?.name ?? "companies-slug-videos-photos___sg",
    path: videos_45photosxSMdXK78PJMeta?.path ?? "videos-photos",
    meta: videos_45photosxSMdXK78PJMeta || {},
    alias: videos_45photosxSMdXK78PJMeta?.alias || [],
    redirect: videos_45photosxSMdXK78PJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue").then(m => m.default || m)
  }
],
    name: _91slug_93d31nPZF9Y1Meta?.name ?? undefined,
    meta: _91slug_93d31nPZF9Y1Meta || {},
    alias: _91slug_93d31nPZF9Y1Meta?.alias || [],
    redirect: _91slug_93d31nPZF9Y1Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93d31nPZF9Y1Meta?.path ?? "/my/companies/:slug()",
    children: [
  {
    name: indexbEt2oo02IbMeta?.name ?? "companies-slug___my",
    path: indexbEt2oo02IbMeta?.path ?? "",
    meta: indexbEt2oo02IbMeta || {},
    alias: indexbEt2oo02IbMeta?.alias || [],
    redirect: indexbEt2oo02IbMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: jobs_45vacancyUpzFtqmwpKMeta?.name ?? "companies-slug-jobs-vacancy___my",
    path: jobs_45vacancyUpzFtqmwpKMeta?.path ?? "jobs-vacancy",
    meta: jobs_45vacancyUpzFtqmwpKMeta || {},
    alias: jobs_45vacancyUpzFtqmwpKMeta?.alias || [],
    redirect: jobs_45vacancyUpzFtqmwpKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue").then(m => m.default || m)
  },
  {
    name: salaries_45benefitsKjta6dzWesMeta?.name ?? "companies-slug-salaries-benefits___my",
    path: salaries_45benefitsKjta6dzWesMeta?.path ?? "salaries-benefits",
    meta: salaries_45benefitsKjta6dzWesMeta || {},
    alias: salaries_45benefitsKjta6dzWesMeta?.alias || [],
    redirect: salaries_45benefitsKjta6dzWesMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue").then(m => m.default || m)
  },
  {
    name: videos_45photosxSMdXK78PJMeta?.name ?? "companies-slug-videos-photos___my",
    path: videos_45photosxSMdXK78PJMeta?.path ?? "videos-photos",
    meta: videos_45photosxSMdXK78PJMeta || {},
    alias: videos_45photosxSMdXK78PJMeta?.alias || [],
    redirect: videos_45photosxSMdXK78PJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue").then(m => m.default || m)
  }
],
    name: _91slug_93d31nPZF9Y1Meta?.name ?? undefined,
    meta: _91slug_93d31nPZF9Y1Meta || {},
    alias: _91slug_93d31nPZF9Y1Meta?.alias || [],
    redirect: _91slug_93d31nPZF9Y1Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93d31nPZF9Y1Meta?.path ?? "/id/companies/:slug()",
    children: [
  {
    name: indexbEt2oo02IbMeta?.name ?? "companies-slug___id",
    path: indexbEt2oo02IbMeta?.path ?? "",
    meta: indexbEt2oo02IbMeta || {},
    alias: indexbEt2oo02IbMeta?.alias || [],
    redirect: indexbEt2oo02IbMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: jobs_45vacancyUpzFtqmwpKMeta?.name ?? "companies-slug-jobs-vacancy___id",
    path: jobs_45vacancyUpzFtqmwpKMeta?.path ?? "jobs-vacancy",
    meta: jobs_45vacancyUpzFtqmwpKMeta || {},
    alias: jobs_45vacancyUpzFtqmwpKMeta?.alias || [],
    redirect: jobs_45vacancyUpzFtqmwpKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue").then(m => m.default || m)
  },
  {
    name: salaries_45benefitsKjta6dzWesMeta?.name ?? "companies-slug-salaries-benefits___id",
    path: salaries_45benefitsKjta6dzWesMeta?.path ?? "salaries-benefits",
    meta: salaries_45benefitsKjta6dzWesMeta || {},
    alias: salaries_45benefitsKjta6dzWesMeta?.alias || [],
    redirect: salaries_45benefitsKjta6dzWesMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue").then(m => m.default || m)
  },
  {
    name: videos_45photosxSMdXK78PJMeta?.name ?? "companies-slug-videos-photos___id",
    path: videos_45photosxSMdXK78PJMeta?.path ?? "videos-photos",
    meta: videos_45photosxSMdXK78PJMeta || {},
    alias: videos_45photosxSMdXK78PJMeta?.alias || [],
    redirect: videos_45photosxSMdXK78PJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue").then(m => m.default || m)
  }
],
    name: _91slug_93d31nPZF9Y1Meta?.name ?? undefined,
    meta: _91slug_93d31nPZF9Y1Meta || {},
    alias: _91slug_93d31nPZF9Y1Meta?.alias || [],
    redirect: _91slug_93d31nPZF9Y1Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93d31nPZF9Y1Meta?.path ?? "/ph/companies/:slug()",
    children: [
  {
    name: indexbEt2oo02IbMeta?.name ?? "companies-slug___ph",
    path: indexbEt2oo02IbMeta?.path ?? "",
    meta: indexbEt2oo02IbMeta || {},
    alias: indexbEt2oo02IbMeta?.alias || [],
    redirect: indexbEt2oo02IbMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: jobs_45vacancyUpzFtqmwpKMeta?.name ?? "companies-slug-jobs-vacancy___ph",
    path: jobs_45vacancyUpzFtqmwpKMeta?.path ?? "jobs-vacancy",
    meta: jobs_45vacancyUpzFtqmwpKMeta || {},
    alias: jobs_45vacancyUpzFtqmwpKMeta?.alias || [],
    redirect: jobs_45vacancyUpzFtqmwpKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/jobs-vacancy.vue").then(m => m.default || m)
  },
  {
    name: salaries_45benefitsKjta6dzWesMeta?.name ?? "companies-slug-salaries-benefits___ph",
    path: salaries_45benefitsKjta6dzWesMeta?.path ?? "salaries-benefits",
    meta: salaries_45benefitsKjta6dzWesMeta || {},
    alias: salaries_45benefitsKjta6dzWesMeta?.alias || [],
    redirect: salaries_45benefitsKjta6dzWesMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/salaries-benefits.vue").then(m => m.default || m)
  },
  {
    name: videos_45photosxSMdXK78PJMeta?.name ?? "companies-slug-videos-photos___ph",
    path: videos_45photosxSMdXK78PJMeta?.path ?? "videos-photos",
    meta: videos_45photosxSMdXK78PJMeta || {},
    alias: videos_45photosxSMdXK78PJMeta?.alias || [],
    redirect: videos_45photosxSMdXK78PJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug]/videos-photos.vue").then(m => m.default || m)
  }
],
    name: _91slug_93d31nPZF9Y1Meta?.name ?? undefined,
    meta: _91slug_93d31nPZF9Y1Meta || {},
    alias: _91slug_93d31nPZF9Y1Meta?.alias || [],
    redirect: _91slug_93d31nPZF9Y1Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexeLYj8ViAViMeta?.name ?? "companies-all___us",
    path: indexeLYj8ViAViMeta?.path ?? "/companies/all",
    meta: indexeLYj8ViAViMeta || {},
    alias: indexeLYj8ViAViMeta?.alias || [],
    redirect: indexeLYj8ViAViMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/index.vue").then(m => m.default || m)
  },
  {
    name: indexeLYj8ViAViMeta?.name ?? "companies-all___gb",
    path: indexeLYj8ViAViMeta?.path ?? "/gb/companies/all",
    meta: indexeLYj8ViAViMeta || {},
    alias: indexeLYj8ViAViMeta?.alias || [],
    redirect: indexeLYj8ViAViMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/index.vue").then(m => m.default || m)
  },
  {
    name: indexeLYj8ViAViMeta?.name ?? "companies-all___sg",
    path: indexeLYj8ViAViMeta?.path ?? "/sg/companies/all",
    meta: indexeLYj8ViAViMeta || {},
    alias: indexeLYj8ViAViMeta?.alias || [],
    redirect: indexeLYj8ViAViMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/index.vue").then(m => m.default || m)
  },
  {
    name: indexeLYj8ViAViMeta?.name ?? "companies-all___my",
    path: indexeLYj8ViAViMeta?.path ?? "/my/companies/all",
    meta: indexeLYj8ViAViMeta || {},
    alias: indexeLYj8ViAViMeta?.alias || [],
    redirect: indexeLYj8ViAViMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/index.vue").then(m => m.default || m)
  },
  {
    name: indexeLYj8ViAViMeta?.name ?? "companies-all___id",
    path: indexeLYj8ViAViMeta?.path ?? "/id/companies/all",
    meta: indexeLYj8ViAViMeta || {},
    alias: indexeLYj8ViAViMeta?.alias || [],
    redirect: indexeLYj8ViAViMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/index.vue").then(m => m.default || m)
  },
  {
    name: indexeLYj8ViAViMeta?.name ?? "companies-all___ph",
    path: indexeLYj8ViAViMeta?.path ?? "/ph/companies/all",
    meta: indexeLYj8ViAViMeta || {},
    alias: indexeLYj8ViAViMeta?.alias || [],
    redirect: indexeLYj8ViAViMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/index.vue").then(m => m.default || m)
  },
  {
    name: starting_45with_45_91key_93bDIgpXyrCeMeta?.name ?? "companies-all-starting-with-key___us",
    path: starting_45with_45_91key_93bDIgpXyrCeMeta?.path ?? "/companies/all/starting-with-:key()",
    meta: starting_45with_45_91key_93bDIgpXyrCeMeta || {},
    alias: starting_45with_45_91key_93bDIgpXyrCeMeta?.alias || [],
    redirect: starting_45with_45_91key_93bDIgpXyrCeMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue").then(m => m.default || m)
  },
  {
    name: starting_45with_45_91key_93bDIgpXyrCeMeta?.name ?? "companies-all-starting-with-key___gb",
    path: starting_45with_45_91key_93bDIgpXyrCeMeta?.path ?? "/gb/companies/all/starting-with-:key()",
    meta: starting_45with_45_91key_93bDIgpXyrCeMeta || {},
    alias: starting_45with_45_91key_93bDIgpXyrCeMeta?.alias || [],
    redirect: starting_45with_45_91key_93bDIgpXyrCeMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue").then(m => m.default || m)
  },
  {
    name: starting_45with_45_91key_93bDIgpXyrCeMeta?.name ?? "companies-all-starting-with-key___sg",
    path: starting_45with_45_91key_93bDIgpXyrCeMeta?.path ?? "/sg/companies/all/starting-with-:key()",
    meta: starting_45with_45_91key_93bDIgpXyrCeMeta || {},
    alias: starting_45with_45_91key_93bDIgpXyrCeMeta?.alias || [],
    redirect: starting_45with_45_91key_93bDIgpXyrCeMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue").then(m => m.default || m)
  },
  {
    name: starting_45with_45_91key_93bDIgpXyrCeMeta?.name ?? "companies-all-starting-with-key___my",
    path: starting_45with_45_91key_93bDIgpXyrCeMeta?.path ?? "/my/companies/all/starting-with-:key()",
    meta: starting_45with_45_91key_93bDIgpXyrCeMeta || {},
    alias: starting_45with_45_91key_93bDIgpXyrCeMeta?.alias || [],
    redirect: starting_45with_45_91key_93bDIgpXyrCeMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue").then(m => m.default || m)
  },
  {
    name: starting_45with_45_91key_93bDIgpXyrCeMeta?.name ?? "companies-all-starting-with-key___id",
    path: starting_45with_45_91key_93bDIgpXyrCeMeta?.path ?? "/id/companies/all/starting-with-:key()",
    meta: starting_45with_45_91key_93bDIgpXyrCeMeta || {},
    alias: starting_45with_45_91key_93bDIgpXyrCeMeta?.alias || [],
    redirect: starting_45with_45_91key_93bDIgpXyrCeMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue").then(m => m.default || m)
  },
  {
    name: starting_45with_45_91key_93bDIgpXyrCeMeta?.name ?? "companies-all-starting-with-key___ph",
    path: starting_45with_45_91key_93bDIgpXyrCeMeta?.path ?? "/ph/companies/all/starting-with-:key()",
    meta: starting_45with_45_91key_93bDIgpXyrCeMeta || {},
    alias: starting_45with_45_91key_93bDIgpXyrCeMeta?.alias || [],
    redirect: starting_45with_45_91key_93bDIgpXyrCeMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/companies/all/starting-with-[key].vue").then(m => m.default || m)
  },
  {
    name: contact2eEeW7EYNKMeta?.name ?? "contact___us",
    path: contact2eEeW7EYNKMeta?.path ?? "/contact",
    meta: contact2eEeW7EYNKMeta || {},
    alias: contact2eEeW7EYNKMeta?.alias || [],
    redirect: contact2eEeW7EYNKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact2eEeW7EYNKMeta?.name ?? "contact___gb",
    path: contact2eEeW7EYNKMeta?.path ?? "/gb/contact",
    meta: contact2eEeW7EYNKMeta || {},
    alias: contact2eEeW7EYNKMeta?.alias || [],
    redirect: contact2eEeW7EYNKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact2eEeW7EYNKMeta?.name ?? "contact___sg",
    path: contact2eEeW7EYNKMeta?.path ?? "/sg/contact",
    meta: contact2eEeW7EYNKMeta || {},
    alias: contact2eEeW7EYNKMeta?.alias || [],
    redirect: contact2eEeW7EYNKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact2eEeW7EYNKMeta?.name ?? "contact___my",
    path: contact2eEeW7EYNKMeta?.path ?? "/my/contact",
    meta: contact2eEeW7EYNKMeta || {},
    alias: contact2eEeW7EYNKMeta?.alias || [],
    redirect: contact2eEeW7EYNKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact2eEeW7EYNKMeta?.name ?? "contact___id",
    path: contact2eEeW7EYNKMeta?.path ?? "/id/contact",
    meta: contact2eEeW7EYNKMeta || {},
    alias: contact2eEeW7EYNKMeta?.alias || [],
    redirect: contact2eEeW7EYNKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact2eEeW7EYNKMeta?.name ?? "contact___ph",
    path: contact2eEeW7EYNKMeta?.path ?? "/ph/contact",
    meta: contact2eEeW7EYNKMeta || {},
    alias: contact2eEeW7EYNKMeta?.alias || [],
    redirect: contact2eEeW7EYNKMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexv4stYbzQNQMeta?.name ?? "continue___us",
    path: indexv4stYbzQNQMeta?.path ?? "/continue",
    meta: indexv4stYbzQNQMeta || {},
    alias: indexv4stYbzQNQMeta?.alias || [],
    redirect: indexv4stYbzQNQMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/index.vue").then(m => m.default || m)
  },
  {
    name: indexv4stYbzQNQMeta?.name ?? "continue___gb",
    path: indexv4stYbzQNQMeta?.path ?? "/gb/continue",
    meta: indexv4stYbzQNQMeta || {},
    alias: indexv4stYbzQNQMeta?.alias || [],
    redirect: indexv4stYbzQNQMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/index.vue").then(m => m.default || m)
  },
  {
    name: indexv4stYbzQNQMeta?.name ?? "continue___sg",
    path: indexv4stYbzQNQMeta?.path ?? "/sg/continue",
    meta: indexv4stYbzQNQMeta || {},
    alias: indexv4stYbzQNQMeta?.alias || [],
    redirect: indexv4stYbzQNQMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/index.vue").then(m => m.default || m)
  },
  {
    name: indexv4stYbzQNQMeta?.name ?? "continue___my",
    path: indexv4stYbzQNQMeta?.path ?? "/my/continue",
    meta: indexv4stYbzQNQMeta || {},
    alias: indexv4stYbzQNQMeta?.alias || [],
    redirect: indexv4stYbzQNQMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/index.vue").then(m => m.default || m)
  },
  {
    name: indexv4stYbzQNQMeta?.name ?? "continue___id",
    path: indexv4stYbzQNQMeta?.path ?? "/id/continue",
    meta: indexv4stYbzQNQMeta || {},
    alias: indexv4stYbzQNQMeta?.alias || [],
    redirect: indexv4stYbzQNQMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/index.vue").then(m => m.default || m)
  },
  {
    name: indexv4stYbzQNQMeta?.name ?? "continue___ph",
    path: indexv4stYbzQNQMeta?.path ?? "/ph/continue",
    meta: indexv4stYbzQNQMeta || {},
    alias: indexv4stYbzQNQMeta?.alias || [],
    redirect: indexv4stYbzQNQMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/index.vue").then(m => m.default || m)
  },
  {
    name: verifysE7xuyqv7ZMeta?.name ?? "continue-verify___us",
    path: verifysE7xuyqv7ZMeta?.path ?? "/continue/verify",
    meta: verifysE7xuyqv7ZMeta || {},
    alias: verifysE7xuyqv7ZMeta?.alias || [],
    redirect: verifysE7xuyqv7ZMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/verify.vue").then(m => m.default || m)
  },
  {
    name: verifysE7xuyqv7ZMeta?.name ?? "continue-verify___gb",
    path: verifysE7xuyqv7ZMeta?.path ?? "/gb/continue/verify",
    meta: verifysE7xuyqv7ZMeta || {},
    alias: verifysE7xuyqv7ZMeta?.alias || [],
    redirect: verifysE7xuyqv7ZMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/verify.vue").then(m => m.default || m)
  },
  {
    name: verifysE7xuyqv7ZMeta?.name ?? "continue-verify___sg",
    path: verifysE7xuyqv7ZMeta?.path ?? "/sg/continue/verify",
    meta: verifysE7xuyqv7ZMeta || {},
    alias: verifysE7xuyqv7ZMeta?.alias || [],
    redirect: verifysE7xuyqv7ZMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/verify.vue").then(m => m.default || m)
  },
  {
    name: verifysE7xuyqv7ZMeta?.name ?? "continue-verify___my",
    path: verifysE7xuyqv7ZMeta?.path ?? "/my/continue/verify",
    meta: verifysE7xuyqv7ZMeta || {},
    alias: verifysE7xuyqv7ZMeta?.alias || [],
    redirect: verifysE7xuyqv7ZMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/verify.vue").then(m => m.default || m)
  },
  {
    name: verifysE7xuyqv7ZMeta?.name ?? "continue-verify___id",
    path: verifysE7xuyqv7ZMeta?.path ?? "/id/continue/verify",
    meta: verifysE7xuyqv7ZMeta || {},
    alias: verifysE7xuyqv7ZMeta?.alias || [],
    redirect: verifysE7xuyqv7ZMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/verify.vue").then(m => m.default || m)
  },
  {
    name: verifysE7xuyqv7ZMeta?.name ?? "continue-verify___ph",
    path: verifysE7xuyqv7ZMeta?.path ?? "/ph/continue/verify",
    meta: verifysE7xuyqv7ZMeta || {},
    alias: verifysE7xuyqv7ZMeta?.alias || [],
    redirect: verifysE7xuyqv7ZMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/continue/verify.vue").then(m => m.default || m)
  },
  {
    name: enhance_45resumeTdPqbEiFZ2Meta?.name ?? "dashboard-enhance-resume___us",
    path: enhance_45resumeTdPqbEiFZ2Meta?.path ?? "/dashboard/enhance-resume",
    meta: enhance_45resumeTdPqbEiFZ2Meta || {},
    alias: enhance_45resumeTdPqbEiFZ2Meta?.alias || [],
    redirect: enhance_45resumeTdPqbEiFZ2Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue").then(m => m.default || m)
  },
  {
    name: enhance_45resumeTdPqbEiFZ2Meta?.name ?? "dashboard-enhance-resume___gb",
    path: enhance_45resumeTdPqbEiFZ2Meta?.path ?? "/gb/dashboard/enhance-resume",
    meta: enhance_45resumeTdPqbEiFZ2Meta || {},
    alias: enhance_45resumeTdPqbEiFZ2Meta?.alias || [],
    redirect: enhance_45resumeTdPqbEiFZ2Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue").then(m => m.default || m)
  },
  {
    name: enhance_45resumeTdPqbEiFZ2Meta?.name ?? "dashboard-enhance-resume___sg",
    path: enhance_45resumeTdPqbEiFZ2Meta?.path ?? "/sg/dashboard/enhance-resume",
    meta: enhance_45resumeTdPqbEiFZ2Meta || {},
    alias: enhance_45resumeTdPqbEiFZ2Meta?.alias || [],
    redirect: enhance_45resumeTdPqbEiFZ2Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue").then(m => m.default || m)
  },
  {
    name: enhance_45resumeTdPqbEiFZ2Meta?.name ?? "dashboard-enhance-resume___my",
    path: enhance_45resumeTdPqbEiFZ2Meta?.path ?? "/my/dashboard/enhance-resume",
    meta: enhance_45resumeTdPqbEiFZ2Meta || {},
    alias: enhance_45resumeTdPqbEiFZ2Meta?.alias || [],
    redirect: enhance_45resumeTdPqbEiFZ2Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue").then(m => m.default || m)
  },
  {
    name: enhance_45resumeTdPqbEiFZ2Meta?.name ?? "dashboard-enhance-resume___id",
    path: enhance_45resumeTdPqbEiFZ2Meta?.path ?? "/id/dashboard/enhance-resume",
    meta: enhance_45resumeTdPqbEiFZ2Meta || {},
    alias: enhance_45resumeTdPqbEiFZ2Meta?.alias || [],
    redirect: enhance_45resumeTdPqbEiFZ2Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue").then(m => m.default || m)
  },
  {
    name: enhance_45resumeTdPqbEiFZ2Meta?.name ?? "dashboard-enhance-resume___ph",
    path: enhance_45resumeTdPqbEiFZ2Meta?.path ?? "/ph/dashboard/enhance-resume",
    meta: enhance_45resumeTdPqbEiFZ2Meta || {},
    alias: enhance_45resumeTdPqbEiFZ2Meta?.alias || [],
    redirect: enhance_45resumeTdPqbEiFZ2Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/enhance-resume.vue").then(m => m.default || m)
  },
  {
    name: indextqQw1jjsklMeta?.name ?? "dashboard___us",
    path: indextqQw1jjsklMeta?.path ?? "/dashboard",
    meta: indextqQw1jjsklMeta || {},
    alias: indextqQw1jjsklMeta?.alias || [],
    redirect: indextqQw1jjsklMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indextqQw1jjsklMeta?.name ?? "dashboard___gb",
    path: indextqQw1jjsklMeta?.path ?? "/gb/dashboard",
    meta: indextqQw1jjsklMeta || {},
    alias: indextqQw1jjsklMeta?.alias || [],
    redirect: indextqQw1jjsklMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indextqQw1jjsklMeta?.name ?? "dashboard___sg",
    path: indextqQw1jjsklMeta?.path ?? "/sg/dashboard",
    meta: indextqQw1jjsklMeta || {},
    alias: indextqQw1jjsklMeta?.alias || [],
    redirect: indextqQw1jjsklMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indextqQw1jjsklMeta?.name ?? "dashboard___my",
    path: indextqQw1jjsklMeta?.path ?? "/my/dashboard",
    meta: indextqQw1jjsklMeta || {},
    alias: indextqQw1jjsklMeta?.alias || [],
    redirect: indextqQw1jjsklMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indextqQw1jjsklMeta?.name ?? "dashboard___id",
    path: indextqQw1jjsklMeta?.path ?? "/id/dashboard",
    meta: indextqQw1jjsklMeta || {},
    alias: indextqQw1jjsklMeta?.alias || [],
    redirect: indextqQw1jjsklMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indextqQw1jjsklMeta?.name ?? "dashboard___ph",
    path: indextqQw1jjsklMeta?.path ?? "/ph/dashboard",
    meta: indextqQw1jjsklMeta || {},
    alias: indextqQw1jjsklMeta?.alias || [],
    redirect: indextqQw1jjsklMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93D6FBCc60AtMeta?.name ?? "dashboard-job-recommendation-uid___us",
    path: _91uid_93D6FBCc60AtMeta?.path ?? "/dashboard/job-recommendation/:uid()",
    meta: _91uid_93D6FBCc60AtMeta || {},
    alias: _91uid_93D6FBCc60AtMeta?.alias || [],
    redirect: _91uid_93D6FBCc60AtMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93D6FBCc60AtMeta?.name ?? "dashboard-job-recommendation-uid___gb",
    path: _91uid_93D6FBCc60AtMeta?.path ?? "/gb/dashboard/job-recommendation/:uid()",
    meta: _91uid_93D6FBCc60AtMeta || {},
    alias: _91uid_93D6FBCc60AtMeta?.alias || [],
    redirect: _91uid_93D6FBCc60AtMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93D6FBCc60AtMeta?.name ?? "dashboard-job-recommendation-uid___sg",
    path: _91uid_93D6FBCc60AtMeta?.path ?? "/sg/dashboard/job-recommendation/:uid()",
    meta: _91uid_93D6FBCc60AtMeta || {},
    alias: _91uid_93D6FBCc60AtMeta?.alias || [],
    redirect: _91uid_93D6FBCc60AtMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93D6FBCc60AtMeta?.name ?? "dashboard-job-recommendation-uid___my",
    path: _91uid_93D6FBCc60AtMeta?.path ?? "/my/dashboard/job-recommendation/:uid()",
    meta: _91uid_93D6FBCc60AtMeta || {},
    alias: _91uid_93D6FBCc60AtMeta?.alias || [],
    redirect: _91uid_93D6FBCc60AtMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93D6FBCc60AtMeta?.name ?? "dashboard-job-recommendation-uid___id",
    path: _91uid_93D6FBCc60AtMeta?.path ?? "/id/dashboard/job-recommendation/:uid()",
    meta: _91uid_93D6FBCc60AtMeta || {},
    alias: _91uid_93D6FBCc60AtMeta?.alias || [],
    redirect: _91uid_93D6FBCc60AtMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93D6FBCc60AtMeta?.name ?? "dashboard-job-recommendation-uid___ph",
    path: _91uid_93D6FBCc60AtMeta?.path ?? "/ph/dashboard/job-recommendation/:uid()",
    meta: _91uid_93D6FBCc60AtMeta || {},
    alias: _91uid_93D6FBCc60AtMeta?.alias || [],
    redirect: _91uid_93D6FBCc60AtMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/[uid].vue").then(m => m.default || m)
  },
  {
    name: fetchtdFVRKBKaiMeta?.name ?? "dashboard-job-recommendation-fetch___us",
    path: fetchtdFVRKBKaiMeta?.path ?? "/dashboard/job-recommendation/fetch",
    meta: fetchtdFVRKBKaiMeta || {},
    alias: fetchtdFVRKBKaiMeta?.alias || [],
    redirect: fetchtdFVRKBKaiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue").then(m => m.default || m)
  },
  {
    name: fetchtdFVRKBKaiMeta?.name ?? "dashboard-job-recommendation-fetch___gb",
    path: fetchtdFVRKBKaiMeta?.path ?? "/gb/dashboard/job-recommendation/fetch",
    meta: fetchtdFVRKBKaiMeta || {},
    alias: fetchtdFVRKBKaiMeta?.alias || [],
    redirect: fetchtdFVRKBKaiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue").then(m => m.default || m)
  },
  {
    name: fetchtdFVRKBKaiMeta?.name ?? "dashboard-job-recommendation-fetch___sg",
    path: fetchtdFVRKBKaiMeta?.path ?? "/sg/dashboard/job-recommendation/fetch",
    meta: fetchtdFVRKBKaiMeta || {},
    alias: fetchtdFVRKBKaiMeta?.alias || [],
    redirect: fetchtdFVRKBKaiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue").then(m => m.default || m)
  },
  {
    name: fetchtdFVRKBKaiMeta?.name ?? "dashboard-job-recommendation-fetch___my",
    path: fetchtdFVRKBKaiMeta?.path ?? "/my/dashboard/job-recommendation/fetch",
    meta: fetchtdFVRKBKaiMeta || {},
    alias: fetchtdFVRKBKaiMeta?.alias || [],
    redirect: fetchtdFVRKBKaiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue").then(m => m.default || m)
  },
  {
    name: fetchtdFVRKBKaiMeta?.name ?? "dashboard-job-recommendation-fetch___id",
    path: fetchtdFVRKBKaiMeta?.path ?? "/id/dashboard/job-recommendation/fetch",
    meta: fetchtdFVRKBKaiMeta || {},
    alias: fetchtdFVRKBKaiMeta?.alias || [],
    redirect: fetchtdFVRKBKaiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue").then(m => m.default || m)
  },
  {
    name: fetchtdFVRKBKaiMeta?.name ?? "dashboard-job-recommendation-fetch___ph",
    path: fetchtdFVRKBKaiMeta?.path ?? "/ph/dashboard/job-recommendation/fetch",
    meta: fetchtdFVRKBKaiMeta || {},
    alias: fetchtdFVRKBKaiMeta?.alias || [],
    redirect: fetchtdFVRKBKaiMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/fetch.vue").then(m => m.default || m)
  },
  {
    name: filteruBcutmYY8yMeta?.name ?? "dashboard-job-recommendation-filter___us",
    path: filteruBcutmYY8yMeta?.path ?? "/dashboard/job-recommendation/filter",
    meta: filteruBcutmYY8yMeta || {},
    alias: filteruBcutmYY8yMeta?.alias || [],
    redirect: filteruBcutmYY8yMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue").then(m => m.default || m)
  },
  {
    name: filteruBcutmYY8yMeta?.name ?? "dashboard-job-recommendation-filter___gb",
    path: filteruBcutmYY8yMeta?.path ?? "/gb/dashboard/job-recommendation/filter",
    meta: filteruBcutmYY8yMeta || {},
    alias: filteruBcutmYY8yMeta?.alias || [],
    redirect: filteruBcutmYY8yMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue").then(m => m.default || m)
  },
  {
    name: filteruBcutmYY8yMeta?.name ?? "dashboard-job-recommendation-filter___sg",
    path: filteruBcutmYY8yMeta?.path ?? "/sg/dashboard/job-recommendation/filter",
    meta: filteruBcutmYY8yMeta || {},
    alias: filteruBcutmYY8yMeta?.alias || [],
    redirect: filteruBcutmYY8yMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue").then(m => m.default || m)
  },
  {
    name: filteruBcutmYY8yMeta?.name ?? "dashboard-job-recommendation-filter___my",
    path: filteruBcutmYY8yMeta?.path ?? "/my/dashboard/job-recommendation/filter",
    meta: filteruBcutmYY8yMeta || {},
    alias: filteruBcutmYY8yMeta?.alias || [],
    redirect: filteruBcutmYY8yMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue").then(m => m.default || m)
  },
  {
    name: filteruBcutmYY8yMeta?.name ?? "dashboard-job-recommendation-filter___id",
    path: filteruBcutmYY8yMeta?.path ?? "/id/dashboard/job-recommendation/filter",
    meta: filteruBcutmYY8yMeta || {},
    alias: filteruBcutmYY8yMeta?.alias || [],
    redirect: filteruBcutmYY8yMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue").then(m => m.default || m)
  },
  {
    name: filteruBcutmYY8yMeta?.name ?? "dashboard-job-recommendation-filter___ph",
    path: filteruBcutmYY8yMeta?.path ?? "/ph/dashboard/job-recommendation/filter",
    meta: filteruBcutmYY8yMeta || {},
    alias: filteruBcutmYY8yMeta?.alias || [],
    redirect: filteruBcutmYY8yMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/filter.vue").then(m => m.default || m)
  },
  {
    name: indextCds34ago5Meta?.name ?? "dashboard-job-recommendation___us",
    path: indextCds34ago5Meta?.path ?? "/dashboard/job-recommendation",
    meta: indextCds34ago5Meta || {},
    alias: indextCds34ago5Meta?.alias || [],
    redirect: indextCds34ago5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue").then(m => m.default || m)
  },
  {
    name: indextCds34ago5Meta?.name ?? "dashboard-job-recommendation___gb",
    path: indextCds34ago5Meta?.path ?? "/gb/dashboard/job-recommendation",
    meta: indextCds34ago5Meta || {},
    alias: indextCds34ago5Meta?.alias || [],
    redirect: indextCds34ago5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue").then(m => m.default || m)
  },
  {
    name: indextCds34ago5Meta?.name ?? "dashboard-job-recommendation___sg",
    path: indextCds34ago5Meta?.path ?? "/sg/dashboard/job-recommendation",
    meta: indextCds34ago5Meta || {},
    alias: indextCds34ago5Meta?.alias || [],
    redirect: indextCds34ago5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue").then(m => m.default || m)
  },
  {
    name: indextCds34ago5Meta?.name ?? "dashboard-job-recommendation___my",
    path: indextCds34ago5Meta?.path ?? "/my/dashboard/job-recommendation",
    meta: indextCds34ago5Meta || {},
    alias: indextCds34ago5Meta?.alias || [],
    redirect: indextCds34ago5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue").then(m => m.default || m)
  },
  {
    name: indextCds34ago5Meta?.name ?? "dashboard-job-recommendation___id",
    path: indextCds34ago5Meta?.path ?? "/id/dashboard/job-recommendation",
    meta: indextCds34ago5Meta || {},
    alias: indextCds34ago5Meta?.alias || [],
    redirect: indextCds34ago5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue").then(m => m.default || m)
  },
  {
    name: indextCds34ago5Meta?.name ?? "dashboard-job-recommendation___ph",
    path: indextCds34ago5Meta?.path ?? "/ph/dashboard/job-recommendation",
    meta: indextCds34ago5Meta || {},
    alias: indextCds34ago5Meta?.alias || [],
    redirect: indextCds34ago5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/job-recommendation/index.vue").then(m => m.default || m)
  },
  {
    name: salary_45reportfzAX4In9sOMeta?.name ?? "dashboard-salary-report___us",
    path: salary_45reportfzAX4In9sOMeta?.path ?? "/dashboard/salary-report",
    meta: salary_45reportfzAX4In9sOMeta || {},
    alias: salary_45reportfzAX4In9sOMeta?.alias || [],
    redirect: salary_45reportfzAX4In9sOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue").then(m => m.default || m)
  },
  {
    name: salary_45reportfzAX4In9sOMeta?.name ?? "dashboard-salary-report___gb",
    path: salary_45reportfzAX4In9sOMeta?.path ?? "/gb/dashboard/salary-report",
    meta: salary_45reportfzAX4In9sOMeta || {},
    alias: salary_45reportfzAX4In9sOMeta?.alias || [],
    redirect: salary_45reportfzAX4In9sOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue").then(m => m.default || m)
  },
  {
    name: salary_45reportfzAX4In9sOMeta?.name ?? "dashboard-salary-report___sg",
    path: salary_45reportfzAX4In9sOMeta?.path ?? "/sg/dashboard/salary-report",
    meta: salary_45reportfzAX4In9sOMeta || {},
    alias: salary_45reportfzAX4In9sOMeta?.alias || [],
    redirect: salary_45reportfzAX4In9sOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue").then(m => m.default || m)
  },
  {
    name: salary_45reportfzAX4In9sOMeta?.name ?? "dashboard-salary-report___my",
    path: salary_45reportfzAX4In9sOMeta?.path ?? "/my/dashboard/salary-report",
    meta: salary_45reportfzAX4In9sOMeta || {},
    alias: salary_45reportfzAX4In9sOMeta?.alias || [],
    redirect: salary_45reportfzAX4In9sOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue").then(m => m.default || m)
  },
  {
    name: salary_45reportfzAX4In9sOMeta?.name ?? "dashboard-salary-report___id",
    path: salary_45reportfzAX4In9sOMeta?.path ?? "/id/dashboard/salary-report",
    meta: salary_45reportfzAX4In9sOMeta || {},
    alias: salary_45reportfzAX4In9sOMeta?.alias || [],
    redirect: salary_45reportfzAX4In9sOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue").then(m => m.default || m)
  },
  {
    name: salary_45reportfzAX4In9sOMeta?.name ?? "dashboard-salary-report___ph",
    path: salary_45reportfzAX4In9sOMeta?.path ?? "/ph/dashboard/salary-report",
    meta: salary_45reportfzAX4In9sOMeta || {},
    alias: salary_45reportfzAX4In9sOMeta?.alias || [],
    redirect: salary_45reportfzAX4In9sOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/dashboard/salary-report.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountgAA5ieHzL9Meta?.name ?? "delete-account___us",
    path: delete_45accountgAA5ieHzL9Meta?.path ?? "/delete-account",
    meta: delete_45accountgAA5ieHzL9Meta || {},
    alias: delete_45accountgAA5ieHzL9Meta?.alias || [],
    redirect: delete_45accountgAA5ieHzL9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountgAA5ieHzL9Meta?.name ?? "delete-account___gb",
    path: delete_45accountgAA5ieHzL9Meta?.path ?? "/gb/delete-account",
    meta: delete_45accountgAA5ieHzL9Meta || {},
    alias: delete_45accountgAA5ieHzL9Meta?.alias || [],
    redirect: delete_45accountgAA5ieHzL9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountgAA5ieHzL9Meta?.name ?? "delete-account___sg",
    path: delete_45accountgAA5ieHzL9Meta?.path ?? "/sg/delete-account",
    meta: delete_45accountgAA5ieHzL9Meta || {},
    alias: delete_45accountgAA5ieHzL9Meta?.alias || [],
    redirect: delete_45accountgAA5ieHzL9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountgAA5ieHzL9Meta?.name ?? "delete-account___my",
    path: delete_45accountgAA5ieHzL9Meta?.path ?? "/my/delete-account",
    meta: delete_45accountgAA5ieHzL9Meta || {},
    alias: delete_45accountgAA5ieHzL9Meta?.alias || [],
    redirect: delete_45accountgAA5ieHzL9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountgAA5ieHzL9Meta?.name ?? "delete-account___id",
    path: delete_45accountgAA5ieHzL9Meta?.path ?? "/id/delete-account",
    meta: delete_45accountgAA5ieHzL9Meta || {},
    alias: delete_45accountgAA5ieHzL9Meta?.alias || [],
    redirect: delete_45accountgAA5ieHzL9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountgAA5ieHzL9Meta?.name ?? "delete-account___ph",
    path: delete_45accountgAA5ieHzL9Meta?.path ?? "/ph/delete-account",
    meta: delete_45accountgAA5ieHzL9Meta || {},
    alias: delete_45accountgAA5ieHzL9Meta?.alias || [],
    redirect: delete_45accountgAA5ieHzL9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: resumeERqHRvdWy7Meta?.name ?? "download-resume___us",
    path: resumeERqHRvdWy7Meta?.path ?? "/download/resume",
    meta: resumeERqHRvdWy7Meta || {},
    alias: resumeERqHRvdWy7Meta?.alias || [],
    redirect: resumeERqHRvdWy7Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/download/resume.vue").then(m => m.default || m)
  },
  {
    name: resumeERqHRvdWy7Meta?.name ?? "download-resume___gb",
    path: resumeERqHRvdWy7Meta?.path ?? "/gb/download/resume",
    meta: resumeERqHRvdWy7Meta || {},
    alias: resumeERqHRvdWy7Meta?.alias || [],
    redirect: resumeERqHRvdWy7Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/download/resume.vue").then(m => m.default || m)
  },
  {
    name: resumeERqHRvdWy7Meta?.name ?? "download-resume___sg",
    path: resumeERqHRvdWy7Meta?.path ?? "/sg/download/resume",
    meta: resumeERqHRvdWy7Meta || {},
    alias: resumeERqHRvdWy7Meta?.alias || [],
    redirect: resumeERqHRvdWy7Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/download/resume.vue").then(m => m.default || m)
  },
  {
    name: resumeERqHRvdWy7Meta?.name ?? "download-resume___my",
    path: resumeERqHRvdWy7Meta?.path ?? "/my/download/resume",
    meta: resumeERqHRvdWy7Meta || {},
    alias: resumeERqHRvdWy7Meta?.alias || [],
    redirect: resumeERqHRvdWy7Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/download/resume.vue").then(m => m.default || m)
  },
  {
    name: resumeERqHRvdWy7Meta?.name ?? "download-resume___id",
    path: resumeERqHRvdWy7Meta?.path ?? "/id/download/resume",
    meta: resumeERqHRvdWy7Meta || {},
    alias: resumeERqHRvdWy7Meta?.alias || [],
    redirect: resumeERqHRvdWy7Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/download/resume.vue").then(m => m.default || m)
  },
  {
    name: resumeERqHRvdWy7Meta?.name ?? "download-resume___ph",
    path: resumeERqHRvdWy7Meta?.path ?? "/ph/download/resume",
    meta: resumeERqHRvdWy7Meta || {},
    alias: resumeERqHRvdWy7Meta?.alias || [],
    redirect: resumeERqHRvdWy7Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/download/resume.vue").then(m => m.default || m)
  },
  {
    name: _91code_93yaDQ6XixDAMeta?.name ?? "errors-code___us",
    path: _91code_93yaDQ6XixDAMeta?.path ?? "/errors/:code()",
    meta: _91code_93yaDQ6XixDAMeta || {},
    alias: _91code_93yaDQ6XixDAMeta?.alias || [],
    redirect: _91code_93yaDQ6XixDAMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93yaDQ6XixDAMeta?.name ?? "errors-code___gb",
    path: _91code_93yaDQ6XixDAMeta?.path ?? "/gb/errors/:code()",
    meta: _91code_93yaDQ6XixDAMeta || {},
    alias: _91code_93yaDQ6XixDAMeta?.alias || [],
    redirect: _91code_93yaDQ6XixDAMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93yaDQ6XixDAMeta?.name ?? "errors-code___sg",
    path: _91code_93yaDQ6XixDAMeta?.path ?? "/sg/errors/:code()",
    meta: _91code_93yaDQ6XixDAMeta || {},
    alias: _91code_93yaDQ6XixDAMeta?.alias || [],
    redirect: _91code_93yaDQ6XixDAMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93yaDQ6XixDAMeta?.name ?? "errors-code___my",
    path: _91code_93yaDQ6XixDAMeta?.path ?? "/my/errors/:code()",
    meta: _91code_93yaDQ6XixDAMeta || {},
    alias: _91code_93yaDQ6XixDAMeta?.alias || [],
    redirect: _91code_93yaDQ6XixDAMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93yaDQ6XixDAMeta?.name ?? "errors-code___id",
    path: _91code_93yaDQ6XixDAMeta?.path ?? "/id/errors/:code()",
    meta: _91code_93yaDQ6XixDAMeta || {},
    alias: _91code_93yaDQ6XixDAMeta?.alias || [],
    redirect: _91code_93yaDQ6XixDAMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93yaDQ6XixDAMeta?.name ?? "errors-code___ph",
    path: _91code_93yaDQ6XixDAMeta?.path ?? "/ph/errors/:code()",
    meta: _91code_93yaDQ6XixDAMeta || {},
    alias: _91code_93yaDQ6XixDAMeta?.alias || [],
    redirect: _91code_93yaDQ6XixDAMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/[code].vue").then(m => m.default || m)
  },
  {
    name: timeoutotkicAMZnJMeta?.name ?? "errors-timeout___us",
    path: timeoutotkicAMZnJMeta?.path ?? "/errors/timeout",
    meta: timeoutotkicAMZnJMeta || {},
    alias: timeoutotkicAMZnJMeta?.alias || [],
    redirect: timeoutotkicAMZnJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/timeout.vue").then(m => m.default || m)
  },
  {
    name: timeoutotkicAMZnJMeta?.name ?? "errors-timeout___gb",
    path: timeoutotkicAMZnJMeta?.path ?? "/gb/errors/timeout",
    meta: timeoutotkicAMZnJMeta || {},
    alias: timeoutotkicAMZnJMeta?.alias || [],
    redirect: timeoutotkicAMZnJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/timeout.vue").then(m => m.default || m)
  },
  {
    name: timeoutotkicAMZnJMeta?.name ?? "errors-timeout___sg",
    path: timeoutotkicAMZnJMeta?.path ?? "/sg/errors/timeout",
    meta: timeoutotkicAMZnJMeta || {},
    alias: timeoutotkicAMZnJMeta?.alias || [],
    redirect: timeoutotkicAMZnJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/timeout.vue").then(m => m.default || m)
  },
  {
    name: timeoutotkicAMZnJMeta?.name ?? "errors-timeout___my",
    path: timeoutotkicAMZnJMeta?.path ?? "/my/errors/timeout",
    meta: timeoutotkicAMZnJMeta || {},
    alias: timeoutotkicAMZnJMeta?.alias || [],
    redirect: timeoutotkicAMZnJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/timeout.vue").then(m => m.default || m)
  },
  {
    name: timeoutotkicAMZnJMeta?.name ?? "errors-timeout___id",
    path: timeoutotkicAMZnJMeta?.path ?? "/id/errors/timeout",
    meta: timeoutotkicAMZnJMeta || {},
    alias: timeoutotkicAMZnJMeta?.alias || [],
    redirect: timeoutotkicAMZnJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/timeout.vue").then(m => m.default || m)
  },
  {
    name: timeoutotkicAMZnJMeta?.name ?? "errors-timeout___ph",
    path: timeoutotkicAMZnJMeta?.path ?? "/ph/errors/timeout",
    meta: timeoutotkicAMZnJMeta || {},
    alias: timeoutotkicAMZnJMeta?.alias || [],
    redirect: timeoutotkicAMZnJMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/errors/timeout.vue").then(m => m.default || m)
  },
  {
    name: faqpi4QqDZFKnMeta?.name ?? "faq___us",
    path: faqpi4QqDZFKnMeta?.path ?? "/faq",
    meta: faqpi4QqDZFKnMeta || {},
    alias: faqpi4QqDZFKnMeta?.alias || [],
    redirect: faqpi4QqDZFKnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqpi4QqDZFKnMeta?.name ?? "faq___gb",
    path: faqpi4QqDZFKnMeta?.path ?? "/gb/faq",
    meta: faqpi4QqDZFKnMeta || {},
    alias: faqpi4QqDZFKnMeta?.alias || [],
    redirect: faqpi4QqDZFKnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqpi4QqDZFKnMeta?.name ?? "faq___sg",
    path: faqpi4QqDZFKnMeta?.path ?? "/sg/faq",
    meta: faqpi4QqDZFKnMeta || {},
    alias: faqpi4QqDZFKnMeta?.alias || [],
    redirect: faqpi4QqDZFKnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqpi4QqDZFKnMeta?.name ?? "faq___my",
    path: faqpi4QqDZFKnMeta?.path ?? "/my/faq",
    meta: faqpi4QqDZFKnMeta || {},
    alias: faqpi4QqDZFKnMeta?.alias || [],
    redirect: faqpi4QqDZFKnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqpi4QqDZFKnMeta?.name ?? "faq___id",
    path: faqpi4QqDZFKnMeta?.path ?? "/id/faq",
    meta: faqpi4QqDZFKnMeta || {},
    alias: faqpi4QqDZFKnMeta?.alias || [],
    redirect: faqpi4QqDZFKnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqpi4QqDZFKnMeta?.name ?? "faq___ph",
    path: faqpi4QqDZFKnMeta?.path ?? "/ph/faq",
    meta: faqpi4QqDZFKnMeta || {},
    alias: faqpi4QqDZFKnMeta?.alias || [],
    redirect: faqpi4QqDZFKnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordgnThb6A7m5Meta?.name ?? "forgot-password___us",
    path: forgot_45passwordgnThb6A7m5Meta?.path ?? "/forgot-password",
    meta: forgot_45passwordgnThb6A7m5Meta || {},
    alias: forgot_45passwordgnThb6A7m5Meta?.alias || [],
    redirect: forgot_45passwordgnThb6A7m5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordgnThb6A7m5Meta?.name ?? "forgot-password___gb",
    path: forgot_45passwordgnThb6A7m5Meta?.path ?? "/gb/forgot-password",
    meta: forgot_45passwordgnThb6A7m5Meta || {},
    alias: forgot_45passwordgnThb6A7m5Meta?.alias || [],
    redirect: forgot_45passwordgnThb6A7m5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordgnThb6A7m5Meta?.name ?? "forgot-password___sg",
    path: forgot_45passwordgnThb6A7m5Meta?.path ?? "/sg/forgot-password",
    meta: forgot_45passwordgnThb6A7m5Meta || {},
    alias: forgot_45passwordgnThb6A7m5Meta?.alias || [],
    redirect: forgot_45passwordgnThb6A7m5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordgnThb6A7m5Meta?.name ?? "forgot-password___my",
    path: forgot_45passwordgnThb6A7m5Meta?.path ?? "/my/forgot-password",
    meta: forgot_45passwordgnThb6A7m5Meta || {},
    alias: forgot_45passwordgnThb6A7m5Meta?.alias || [],
    redirect: forgot_45passwordgnThb6A7m5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordgnThb6A7m5Meta?.name ?? "forgot-password___id",
    path: forgot_45passwordgnThb6A7m5Meta?.path ?? "/id/forgot-password",
    meta: forgot_45passwordgnThb6A7m5Meta || {},
    alias: forgot_45passwordgnThb6A7m5Meta?.alias || [],
    redirect: forgot_45passwordgnThb6A7m5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordgnThb6A7m5Meta?.name ?? "forgot-password___ph",
    path: forgot_45passwordgnThb6A7m5Meta?.path ?? "/ph/forgot-password",
    meta: forgot_45passwordgnThb6A7m5Meta || {},
    alias: forgot_45passwordgnThb6A7m5Meta?.alias || [],
    redirect: forgot_45passwordgnThb6A7m5Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexe3X76aWWI3Meta?.name ?? "index___us",
    path: indexe3X76aWWI3Meta?.path ?? "/",
    meta: indexe3X76aWWI3Meta || {},
    alias: indexe3X76aWWI3Meta?.alias || [],
    redirect: indexe3X76aWWI3Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3X76aWWI3Meta?.name ?? "index___gb",
    path: indexe3X76aWWI3Meta?.path ?? "/gb",
    meta: indexe3X76aWWI3Meta || {},
    alias: indexe3X76aWWI3Meta?.alias || [],
    redirect: indexe3X76aWWI3Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3X76aWWI3Meta?.name ?? "index___sg",
    path: indexe3X76aWWI3Meta?.path ?? "/sg",
    meta: indexe3X76aWWI3Meta || {},
    alias: indexe3X76aWWI3Meta?.alias || [],
    redirect: indexe3X76aWWI3Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3X76aWWI3Meta?.name ?? "index___my",
    path: indexe3X76aWWI3Meta?.path ?? "/my",
    meta: indexe3X76aWWI3Meta || {},
    alias: indexe3X76aWWI3Meta?.alias || [],
    redirect: indexe3X76aWWI3Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3X76aWWI3Meta?.name ?? "index___id",
    path: indexe3X76aWWI3Meta?.path ?? "/id",
    meta: indexe3X76aWWI3Meta || {},
    alias: indexe3X76aWWI3Meta?.alias || [],
    redirect: indexe3X76aWWI3Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe3X76aWWI3Meta?.name ?? "index___ph",
    path: indexe3X76aWWI3Meta?.path ?? "/ph",
    meta: indexe3X76aWWI3Meta || {},
    alias: indexe3X76aWWI3Meta?.alias || [],
    redirect: indexe3X76aWWI3Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93JkZKlXxGiXMeta?.name ?? "job-id-slug___us",
    path: _91id_93_45_91slug_93JkZKlXxGiXMeta?.path ?? "/job/:id()-:slug()",
    meta: _91id_93_45_91slug_93JkZKlXxGiXMeta || {},
    alias: _91id_93_45_91slug_93JkZKlXxGiXMeta?.alias || [],
    redirect: _91id_93_45_91slug_93JkZKlXxGiXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93JkZKlXxGiXMeta?.name ?? "job-id-slug___gb",
    path: _91id_93_45_91slug_93JkZKlXxGiXMeta?.path ?? "/gb/job/:id()-:slug()",
    meta: _91id_93_45_91slug_93JkZKlXxGiXMeta || {},
    alias: _91id_93_45_91slug_93JkZKlXxGiXMeta?.alias || [],
    redirect: _91id_93_45_91slug_93JkZKlXxGiXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93JkZKlXxGiXMeta?.name ?? "job-id-slug___sg",
    path: _91id_93_45_91slug_93JkZKlXxGiXMeta?.path ?? "/sg/job/:id()-:slug()",
    meta: _91id_93_45_91slug_93JkZKlXxGiXMeta || {},
    alias: _91id_93_45_91slug_93JkZKlXxGiXMeta?.alias || [],
    redirect: _91id_93_45_91slug_93JkZKlXxGiXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93JkZKlXxGiXMeta?.name ?? "job-id-slug___my",
    path: _91id_93_45_91slug_93JkZKlXxGiXMeta?.path ?? "/my/job/:id()-:slug()",
    meta: _91id_93_45_91slug_93JkZKlXxGiXMeta || {},
    alias: _91id_93_45_91slug_93JkZKlXxGiXMeta?.alias || [],
    redirect: _91id_93_45_91slug_93JkZKlXxGiXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93JkZKlXxGiXMeta?.name ?? "job-id-slug___id",
    path: _91id_93_45_91slug_93JkZKlXxGiXMeta?.path ?? "/id/job/:id()-:slug()",
    meta: _91id_93_45_91slug_93JkZKlXxGiXMeta || {},
    alias: _91id_93_45_91slug_93JkZKlXxGiXMeta?.alias || [],
    redirect: _91id_93_45_91slug_93JkZKlXxGiXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93JkZKlXxGiXMeta?.name ?? "job-id-slug___ph",
    path: _91id_93_45_91slug_93JkZKlXxGiXMeta?.path ?? "/ph/job/:id()-:slug()",
    meta: _91id_93_45_91slug_93JkZKlXxGiXMeta || {},
    alias: _91id_93_45_91slug_93JkZKlXxGiXMeta?.alias || [],
    redirect: _91id_93_45_91slug_93JkZKlXxGiXMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/job/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: indexF0us1CI08lMeta?.name ?? "jobs-cat-jobtitle___us",
    path: indexF0us1CI08lMeta?.path ?? "/jobs/:cat()/:jobtitle()",
    meta: indexF0us1CI08lMeta || {},
    alias: indexF0us1CI08lMeta?.alias || [],
    redirect: indexF0us1CI08lMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0us1CI08lMeta?.name ?? "jobs-cat-jobtitle___gb",
    path: indexF0us1CI08lMeta?.path ?? "/gb/jobs/:cat()/:jobtitle()",
    meta: indexF0us1CI08lMeta || {},
    alias: indexF0us1CI08lMeta?.alias || [],
    redirect: indexF0us1CI08lMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0us1CI08lMeta?.name ?? "jobs-cat-jobtitle___sg",
    path: indexF0us1CI08lMeta?.path ?? "/sg/jobs/:cat()/:jobtitle()",
    meta: indexF0us1CI08lMeta || {},
    alias: indexF0us1CI08lMeta?.alias || [],
    redirect: indexF0us1CI08lMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0us1CI08lMeta?.name ?? "jobs-cat-jobtitle___my",
    path: indexF0us1CI08lMeta?.path ?? "/my/jobs/:cat()/:jobtitle()",
    meta: indexF0us1CI08lMeta || {},
    alias: indexF0us1CI08lMeta?.alias || [],
    redirect: indexF0us1CI08lMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0us1CI08lMeta?.name ?? "jobs-cat-jobtitle___id",
    path: indexF0us1CI08lMeta?.path ?? "/id/jobs/:cat()/:jobtitle()",
    meta: indexF0us1CI08lMeta || {},
    alias: indexF0us1CI08lMeta?.alias || [],
    redirect: indexF0us1CI08lMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0us1CI08lMeta?.name ?? "jobs-cat-jobtitle___ph",
    path: indexF0us1CI08lMeta?.path ?? "/ph/jobs/:cat()/:jobtitle()",
    meta: indexF0us1CI08lMeta || {},
    alias: indexF0us1CI08lMeta?.alias || [],
    redirect: indexF0us1CI08lMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/[jobtitle]/index.vue").then(m => m.default || m)
  },
  {
    name: indextjFg1OdSskMeta?.name ?? "jobs-cat___us",
    path: indextjFg1OdSskMeta?.path ?? "/jobs/:cat()",
    meta: indextjFg1OdSskMeta || {},
    alias: indextjFg1OdSskMeta?.alias || [],
    redirect: indextjFg1OdSskMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue").then(m => m.default || m)
  },
  {
    name: indextjFg1OdSskMeta?.name ?? "jobs-cat___gb",
    path: indextjFg1OdSskMeta?.path ?? "/gb/jobs/:cat()",
    meta: indextjFg1OdSskMeta || {},
    alias: indextjFg1OdSskMeta?.alias || [],
    redirect: indextjFg1OdSskMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue").then(m => m.default || m)
  },
  {
    name: indextjFg1OdSskMeta?.name ?? "jobs-cat___sg",
    path: indextjFg1OdSskMeta?.path ?? "/sg/jobs/:cat()",
    meta: indextjFg1OdSskMeta || {},
    alias: indextjFg1OdSskMeta?.alias || [],
    redirect: indextjFg1OdSskMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue").then(m => m.default || m)
  },
  {
    name: indextjFg1OdSskMeta?.name ?? "jobs-cat___my",
    path: indextjFg1OdSskMeta?.path ?? "/my/jobs/:cat()",
    meta: indextjFg1OdSskMeta || {},
    alias: indextjFg1OdSskMeta?.alias || [],
    redirect: indextjFg1OdSskMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue").then(m => m.default || m)
  },
  {
    name: indextjFg1OdSskMeta?.name ?? "jobs-cat___id",
    path: indextjFg1OdSskMeta?.path ?? "/id/jobs/:cat()",
    meta: indextjFg1OdSskMeta || {},
    alias: indextjFg1OdSskMeta?.alias || [],
    redirect: indextjFg1OdSskMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue").then(m => m.default || m)
  },
  {
    name: indextjFg1OdSskMeta?.name ?? "jobs-cat___ph",
    path: indextjFg1OdSskMeta?.path ?? "/ph/jobs/:cat()",
    meta: indextjFg1OdSskMeta || {},
    alias: indextjFg1OdSskMeta?.alias || [],
    redirect: indextjFg1OdSskMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/[cat]/index.vue").then(m => m.default || m)
  },
  {
    name: by_45companyrVdpd5locyMeta?.name ?? "jobs-by-company___us",
    path: by_45companyrVdpd5locyMeta?.path ?? "/jobs/by-company",
    meta: by_45companyrVdpd5locyMeta || {},
    alias: by_45companyrVdpd5locyMeta?.alias || [],
    redirect: by_45companyrVdpd5locyMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/by-company.vue").then(m => m.default || m)
  },
  {
    name: by_45companyrVdpd5locyMeta?.name ?? "jobs-by-company___gb",
    path: by_45companyrVdpd5locyMeta?.path ?? "/gb/jobs/by-company",
    meta: by_45companyrVdpd5locyMeta || {},
    alias: by_45companyrVdpd5locyMeta?.alias || [],
    redirect: by_45companyrVdpd5locyMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/by-company.vue").then(m => m.default || m)
  },
  {
    name: by_45companyrVdpd5locyMeta?.name ?? "jobs-by-company___sg",
    path: by_45companyrVdpd5locyMeta?.path ?? "/sg/jobs/by-company",
    meta: by_45companyrVdpd5locyMeta || {},
    alias: by_45companyrVdpd5locyMeta?.alias || [],
    redirect: by_45companyrVdpd5locyMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/by-company.vue").then(m => m.default || m)
  },
  {
    name: by_45companyrVdpd5locyMeta?.name ?? "jobs-by-company___my",
    path: by_45companyrVdpd5locyMeta?.path ?? "/my/jobs/by-company",
    meta: by_45companyrVdpd5locyMeta || {},
    alias: by_45companyrVdpd5locyMeta?.alias || [],
    redirect: by_45companyrVdpd5locyMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/by-company.vue").then(m => m.default || m)
  },
  {
    name: by_45companyrVdpd5locyMeta?.name ?? "jobs-by-company___id",
    path: by_45companyrVdpd5locyMeta?.path ?? "/id/jobs/by-company",
    meta: by_45companyrVdpd5locyMeta || {},
    alias: by_45companyrVdpd5locyMeta?.alias || [],
    redirect: by_45companyrVdpd5locyMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/by-company.vue").then(m => m.default || m)
  },
  {
    name: by_45companyrVdpd5locyMeta?.name ?? "jobs-by-company___ph",
    path: by_45companyrVdpd5locyMeta?.path ?? "/ph/jobs/by-company",
    meta: by_45companyrVdpd5locyMeta || {},
    alias: by_45companyrVdpd5locyMeta?.alias || [],
    redirect: by_45companyrVdpd5locyMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/by-company.vue").then(m => m.default || m)
  },
  {
    name: index8bOdJonu9SMeta?.name ?? "jobs___us",
    path: index8bOdJonu9SMeta?.path ?? "/jobs",
    meta: index8bOdJonu9SMeta || {},
    alias: index8bOdJonu9SMeta?.alias || [],
    redirect: index8bOdJonu9SMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: index8bOdJonu9SMeta?.name ?? "jobs___gb",
    path: index8bOdJonu9SMeta?.path ?? "/gb/jobs",
    meta: index8bOdJonu9SMeta || {},
    alias: index8bOdJonu9SMeta?.alias || [],
    redirect: index8bOdJonu9SMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: index8bOdJonu9SMeta?.name ?? "jobs___sg",
    path: index8bOdJonu9SMeta?.path ?? "/sg/jobs",
    meta: index8bOdJonu9SMeta || {},
    alias: index8bOdJonu9SMeta?.alias || [],
    redirect: index8bOdJonu9SMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: index8bOdJonu9SMeta?.name ?? "jobs___my",
    path: index8bOdJonu9SMeta?.path ?? "/my/jobs",
    meta: index8bOdJonu9SMeta || {},
    alias: index8bOdJonu9SMeta?.alias || [],
    redirect: index8bOdJonu9SMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: index8bOdJonu9SMeta?.name ?? "jobs___id",
    path: index8bOdJonu9SMeta?.path ?? "/id/jobs",
    meta: index8bOdJonu9SMeta || {},
    alias: index8bOdJonu9SMeta?.alias || [],
    redirect: index8bOdJonu9SMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: index8bOdJonu9SMeta?.name ?? "jobs___ph",
    path: index8bOdJonu9SMeta?.path ?? "/ph/jobs",
    meta: index8bOdJonu9SMeta || {},
    alias: index8bOdJonu9SMeta?.alias || [],
    redirect: index8bOdJonu9SMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: logoutb7oC3mZKxFMeta?.name ?? "logout___us",
    path: logoutb7oC3mZKxFMeta?.path ?? "/logout",
    meta: logoutb7oC3mZKxFMeta || {},
    alias: logoutb7oC3mZKxFMeta?.alias || [],
    redirect: logoutb7oC3mZKxFMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutb7oC3mZKxFMeta?.name ?? "logout___gb",
    path: logoutb7oC3mZKxFMeta?.path ?? "/gb/logout",
    meta: logoutb7oC3mZKxFMeta || {},
    alias: logoutb7oC3mZKxFMeta?.alias || [],
    redirect: logoutb7oC3mZKxFMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutb7oC3mZKxFMeta?.name ?? "logout___sg",
    path: logoutb7oC3mZKxFMeta?.path ?? "/sg/logout",
    meta: logoutb7oC3mZKxFMeta || {},
    alias: logoutb7oC3mZKxFMeta?.alias || [],
    redirect: logoutb7oC3mZKxFMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutb7oC3mZKxFMeta?.name ?? "logout___my",
    path: logoutb7oC3mZKxFMeta?.path ?? "/my/logout",
    meta: logoutb7oC3mZKxFMeta || {},
    alias: logoutb7oC3mZKxFMeta?.alias || [],
    redirect: logoutb7oC3mZKxFMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutb7oC3mZKxFMeta?.name ?? "logout___id",
    path: logoutb7oC3mZKxFMeta?.path ?? "/id/logout",
    meta: logoutb7oC3mZKxFMeta || {},
    alias: logoutb7oC3mZKxFMeta?.alias || [],
    redirect: logoutb7oC3mZKxFMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutb7oC3mZKxFMeta?.name ?? "logout___ph",
    path: logoutb7oC3mZKxFMeta?.path ?? "/ph/logout",
    meta: logoutb7oC3mZKxFMeta || {},
    alias: logoutb7oC3mZKxFMeta?.alias || [],
    redirect: logoutb7oC3mZKxFMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeP9Lsp4qDSfMeta?.name ?? "notification-unsubscribe___us",
    path: unsubscribeP9Lsp4qDSfMeta?.path ?? "/notification/unsubscribe",
    meta: unsubscribeP9Lsp4qDSfMeta || {},
    alias: unsubscribeP9Lsp4qDSfMeta?.alias || [],
    redirect: unsubscribeP9Lsp4qDSfMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeP9Lsp4qDSfMeta?.name ?? "notification-unsubscribe___gb",
    path: unsubscribeP9Lsp4qDSfMeta?.path ?? "/gb/notification/unsubscribe",
    meta: unsubscribeP9Lsp4qDSfMeta || {},
    alias: unsubscribeP9Lsp4qDSfMeta?.alias || [],
    redirect: unsubscribeP9Lsp4qDSfMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeP9Lsp4qDSfMeta?.name ?? "notification-unsubscribe___sg",
    path: unsubscribeP9Lsp4qDSfMeta?.path ?? "/sg/notification/unsubscribe",
    meta: unsubscribeP9Lsp4qDSfMeta || {},
    alias: unsubscribeP9Lsp4qDSfMeta?.alias || [],
    redirect: unsubscribeP9Lsp4qDSfMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeP9Lsp4qDSfMeta?.name ?? "notification-unsubscribe___my",
    path: unsubscribeP9Lsp4qDSfMeta?.path ?? "/my/notification/unsubscribe",
    meta: unsubscribeP9Lsp4qDSfMeta || {},
    alias: unsubscribeP9Lsp4qDSfMeta?.alias || [],
    redirect: unsubscribeP9Lsp4qDSfMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeP9Lsp4qDSfMeta?.name ?? "notification-unsubscribe___id",
    path: unsubscribeP9Lsp4qDSfMeta?.path ?? "/id/notification/unsubscribe",
    meta: unsubscribeP9Lsp4qDSfMeta || {},
    alias: unsubscribeP9Lsp4qDSfMeta?.alias || [],
    redirect: unsubscribeP9Lsp4qDSfMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: unsubscribeP9Lsp4qDSfMeta?.name ?? "notification-unsubscribe___ph",
    path: unsubscribeP9Lsp4qDSfMeta?.path ?? "/ph/notification/unsubscribe",
    meta: unsubscribeP9Lsp4qDSfMeta || {},
    alias: unsubscribeP9Lsp4qDSfMeta?.alias || [],
    redirect: unsubscribeP9Lsp4qDSfMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/notification/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: indexYv5hEmbXfHMeta?.name ?? "onboard___us",
    path: indexYv5hEmbXfHMeta?.path ?? "/onboard",
    meta: indexYv5hEmbXfHMeta || {},
    alias: indexYv5hEmbXfHMeta?.alias || [],
    redirect: indexYv5hEmbXfHMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYv5hEmbXfHMeta?.name ?? "onboard___gb",
    path: indexYv5hEmbXfHMeta?.path ?? "/gb/onboard",
    meta: indexYv5hEmbXfHMeta || {},
    alias: indexYv5hEmbXfHMeta?.alias || [],
    redirect: indexYv5hEmbXfHMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYv5hEmbXfHMeta?.name ?? "onboard___sg",
    path: indexYv5hEmbXfHMeta?.path ?? "/sg/onboard",
    meta: indexYv5hEmbXfHMeta || {},
    alias: indexYv5hEmbXfHMeta?.alias || [],
    redirect: indexYv5hEmbXfHMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYv5hEmbXfHMeta?.name ?? "onboard___my",
    path: indexYv5hEmbXfHMeta?.path ?? "/my/onboard",
    meta: indexYv5hEmbXfHMeta || {},
    alias: indexYv5hEmbXfHMeta?.alias || [],
    redirect: indexYv5hEmbXfHMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYv5hEmbXfHMeta?.name ?? "onboard___id",
    path: indexYv5hEmbXfHMeta?.path ?? "/id/onboard",
    meta: indexYv5hEmbXfHMeta || {},
    alias: indexYv5hEmbXfHMeta?.alias || [],
    redirect: indexYv5hEmbXfHMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYv5hEmbXfHMeta?.name ?? "onboard___ph",
    path: indexYv5hEmbXfHMeta?.path ?? "/ph/onboard",
    meta: indexYv5hEmbXfHMeta || {},
    alias: indexYv5hEmbXfHMeta?.alias || [],
    redirect: indexYv5hEmbXfHMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93StOH4YwNfcMeta?.name ?? "page-uid___us",
    path: _91uid_93StOH4YwNfcMeta?.path ?? "/page/:uid()",
    meta: _91uid_93StOH4YwNfcMeta || {},
    alias: _91uid_93StOH4YwNfcMeta?.alias || [],
    redirect: _91uid_93StOH4YwNfcMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/page/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93StOH4YwNfcMeta?.name ?? "page-uid___gb",
    path: _91uid_93StOH4YwNfcMeta?.path ?? "/gb/page/:uid()",
    meta: _91uid_93StOH4YwNfcMeta || {},
    alias: _91uid_93StOH4YwNfcMeta?.alias || [],
    redirect: _91uid_93StOH4YwNfcMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/page/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93StOH4YwNfcMeta?.name ?? "page-uid___sg",
    path: _91uid_93StOH4YwNfcMeta?.path ?? "/sg/page/:uid()",
    meta: _91uid_93StOH4YwNfcMeta || {},
    alias: _91uid_93StOH4YwNfcMeta?.alias || [],
    redirect: _91uid_93StOH4YwNfcMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/page/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93StOH4YwNfcMeta?.name ?? "page-uid___my",
    path: _91uid_93StOH4YwNfcMeta?.path ?? "/my/page/:uid()",
    meta: _91uid_93StOH4YwNfcMeta || {},
    alias: _91uid_93StOH4YwNfcMeta?.alias || [],
    redirect: _91uid_93StOH4YwNfcMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/page/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93StOH4YwNfcMeta?.name ?? "page-uid___id",
    path: _91uid_93StOH4YwNfcMeta?.path ?? "/id/page/:uid()",
    meta: _91uid_93StOH4YwNfcMeta || {},
    alias: _91uid_93StOH4YwNfcMeta?.alias || [],
    redirect: _91uid_93StOH4YwNfcMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/page/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93StOH4YwNfcMeta?.name ?? "page-uid___ph",
    path: _91uid_93StOH4YwNfcMeta?.path ?? "/ph/page/:uid()",
    meta: _91uid_93StOH4YwNfcMeta || {},
    alias: _91uid_93StOH4YwNfcMeta?.alias || [],
    redirect: _91uid_93StOH4YwNfcMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/page/[uid].vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7hhikZVQWCMeta?.name ?? "privacy-policy___us",
    path: privacy_45policy7hhikZVQWCMeta?.path ?? "/privacy-policy",
    meta: privacy_45policy7hhikZVQWCMeta || {},
    alias: privacy_45policy7hhikZVQWCMeta?.alias || [],
    redirect: privacy_45policy7hhikZVQWCMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7hhikZVQWCMeta?.name ?? "privacy-policy___gb",
    path: privacy_45policy7hhikZVQWCMeta?.path ?? "/gb/privacy-policy",
    meta: privacy_45policy7hhikZVQWCMeta || {},
    alias: privacy_45policy7hhikZVQWCMeta?.alias || [],
    redirect: privacy_45policy7hhikZVQWCMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7hhikZVQWCMeta?.name ?? "privacy-policy___sg",
    path: privacy_45policy7hhikZVQWCMeta?.path ?? "/sg/privacy-policy",
    meta: privacy_45policy7hhikZVQWCMeta || {},
    alias: privacy_45policy7hhikZVQWCMeta?.alias || [],
    redirect: privacy_45policy7hhikZVQWCMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7hhikZVQWCMeta?.name ?? "privacy-policy___my",
    path: privacy_45policy7hhikZVQWCMeta?.path ?? "/my/privacy-policy",
    meta: privacy_45policy7hhikZVQWCMeta || {},
    alias: privacy_45policy7hhikZVQWCMeta?.alias || [],
    redirect: privacy_45policy7hhikZVQWCMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7hhikZVQWCMeta?.name ?? "privacy-policy___id",
    path: privacy_45policy7hhikZVQWCMeta?.path ?? "/id/privacy-policy",
    meta: privacy_45policy7hhikZVQWCMeta || {},
    alias: privacy_45policy7hhikZVQWCMeta?.alias || [],
    redirect: privacy_45policy7hhikZVQWCMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy7hhikZVQWCMeta?.name ?? "privacy-policy___ph",
    path: privacy_45policy7hhikZVQWCMeta?.path ?? "/ph/privacy-policy",
    meta: privacy_45policy7hhikZVQWCMeta || {},
    alias: privacy_45policy7hhikZVQWCMeta?.alias || [],
    redirect: privacy_45policy7hhikZVQWCMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apply6mzx5S65CnMeta?.name ?? "redirect-apply___us",
    path: apply6mzx5S65CnMeta?.path ?? "/redirect/apply",
    meta: apply6mzx5S65CnMeta || {},
    alias: apply6mzx5S65CnMeta?.alias || [],
    redirect: apply6mzx5S65CnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/redirect/apply.vue").then(m => m.default || m)
  },
  {
    name: apply6mzx5S65CnMeta?.name ?? "redirect-apply___gb",
    path: apply6mzx5S65CnMeta?.path ?? "/gb/redirect/apply",
    meta: apply6mzx5S65CnMeta || {},
    alias: apply6mzx5S65CnMeta?.alias || [],
    redirect: apply6mzx5S65CnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/redirect/apply.vue").then(m => m.default || m)
  },
  {
    name: apply6mzx5S65CnMeta?.name ?? "redirect-apply___sg",
    path: apply6mzx5S65CnMeta?.path ?? "/sg/redirect/apply",
    meta: apply6mzx5S65CnMeta || {},
    alias: apply6mzx5S65CnMeta?.alias || [],
    redirect: apply6mzx5S65CnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/redirect/apply.vue").then(m => m.default || m)
  },
  {
    name: apply6mzx5S65CnMeta?.name ?? "redirect-apply___my",
    path: apply6mzx5S65CnMeta?.path ?? "/my/redirect/apply",
    meta: apply6mzx5S65CnMeta || {},
    alias: apply6mzx5S65CnMeta?.alias || [],
    redirect: apply6mzx5S65CnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/redirect/apply.vue").then(m => m.default || m)
  },
  {
    name: apply6mzx5S65CnMeta?.name ?? "redirect-apply___id",
    path: apply6mzx5S65CnMeta?.path ?? "/id/redirect/apply",
    meta: apply6mzx5S65CnMeta || {},
    alias: apply6mzx5S65CnMeta?.alias || [],
    redirect: apply6mzx5S65CnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/redirect/apply.vue").then(m => m.default || m)
  },
  {
    name: apply6mzx5S65CnMeta?.name ?? "redirect-apply___ph",
    path: apply6mzx5S65CnMeta?.path ?? "/ph/redirect/apply",
    meta: apply6mzx5S65CnMeta || {},
    alias: apply6mzx5S65CnMeta?.alias || [],
    redirect: apply6mzx5S65CnMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/redirect/apply.vue").then(m => m.default || m)
  },
  {
    name: _91location_93HZOAJMezhoMeta?.name ?? "salary-report-jobTitle-location___us",
    path: _91location_93HZOAJMezhoMeta?.path ?? "/salary-report/:jobTitle()/:location()",
    meta: _91location_93HZOAJMezhoMeta || {},
    alias: _91location_93HZOAJMezhoMeta?.alias || [],
    redirect: _91location_93HZOAJMezhoMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue").then(m => m.default || m)
  },
  {
    name: _91location_93HZOAJMezhoMeta?.name ?? "salary-report-jobTitle-location___gb",
    path: _91location_93HZOAJMezhoMeta?.path ?? "/gb/salary-report/:jobTitle()/:location()",
    meta: _91location_93HZOAJMezhoMeta || {},
    alias: _91location_93HZOAJMezhoMeta?.alias || [],
    redirect: _91location_93HZOAJMezhoMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue").then(m => m.default || m)
  },
  {
    name: _91location_93HZOAJMezhoMeta?.name ?? "salary-report-jobTitle-location___sg",
    path: _91location_93HZOAJMezhoMeta?.path ?? "/sg/salary-report/:jobTitle()/:location()",
    meta: _91location_93HZOAJMezhoMeta || {},
    alias: _91location_93HZOAJMezhoMeta?.alias || [],
    redirect: _91location_93HZOAJMezhoMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue").then(m => m.default || m)
  },
  {
    name: _91location_93HZOAJMezhoMeta?.name ?? "salary-report-jobTitle-location___my",
    path: _91location_93HZOAJMezhoMeta?.path ?? "/my/salary-report/:jobTitle()/:location()",
    meta: _91location_93HZOAJMezhoMeta || {},
    alias: _91location_93HZOAJMezhoMeta?.alias || [],
    redirect: _91location_93HZOAJMezhoMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue").then(m => m.default || m)
  },
  {
    name: _91location_93HZOAJMezhoMeta?.name ?? "salary-report-jobTitle-location___id",
    path: _91location_93HZOAJMezhoMeta?.path ?? "/id/salary-report/:jobTitle()/:location()",
    meta: _91location_93HZOAJMezhoMeta || {},
    alias: _91location_93HZOAJMezhoMeta?.alias || [],
    redirect: _91location_93HZOAJMezhoMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue").then(m => m.default || m)
  },
  {
    name: _91location_93HZOAJMezhoMeta?.name ?? "salary-report-jobTitle-location___ph",
    path: _91location_93HZOAJMezhoMeta?.path ?? "/ph/salary-report/:jobTitle()/:location()",
    meta: _91location_93HZOAJMezhoMeta || {},
    alias: _91location_93HZOAJMezhoMeta?.alias || [],
    redirect: _91location_93HZOAJMezhoMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/salary-report/[jobTitle]/[location].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46search_938A2XZUsueOMeta?.name ?? "search-search___us",
    path: _91_46_46_46search_938A2XZUsueOMeta?.path ?? "/search/:search(.*)*",
    meta: _91_46_46_46search_938A2XZUsueOMeta || {},
    alias: _91_46_46_46search_938A2XZUsueOMeta?.alias || [],
    redirect: _91_46_46_46search_938A2XZUsueOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/search/[...search].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46search_938A2XZUsueOMeta?.name ?? "search-search___gb",
    path: _91_46_46_46search_938A2XZUsueOMeta?.path ?? "/gb/search/:search(.*)*",
    meta: _91_46_46_46search_938A2XZUsueOMeta || {},
    alias: _91_46_46_46search_938A2XZUsueOMeta?.alias || [],
    redirect: _91_46_46_46search_938A2XZUsueOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/search/[...search].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46search_938A2XZUsueOMeta?.name ?? "search-search___sg",
    path: _91_46_46_46search_938A2XZUsueOMeta?.path ?? "/sg/search/:search(.*)*",
    meta: _91_46_46_46search_938A2XZUsueOMeta || {},
    alias: _91_46_46_46search_938A2XZUsueOMeta?.alias || [],
    redirect: _91_46_46_46search_938A2XZUsueOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/search/[...search].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46search_938A2XZUsueOMeta?.name ?? "search-search___my",
    path: _91_46_46_46search_938A2XZUsueOMeta?.path ?? "/my/search/:search(.*)*",
    meta: _91_46_46_46search_938A2XZUsueOMeta || {},
    alias: _91_46_46_46search_938A2XZUsueOMeta?.alias || [],
    redirect: _91_46_46_46search_938A2XZUsueOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/search/[...search].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46search_938A2XZUsueOMeta?.name ?? "search-search___id",
    path: _91_46_46_46search_938A2XZUsueOMeta?.path ?? "/id/search/:search(.*)*",
    meta: _91_46_46_46search_938A2XZUsueOMeta || {},
    alias: _91_46_46_46search_938A2XZUsueOMeta?.alias || [],
    redirect: _91_46_46_46search_938A2XZUsueOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/search/[...search].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46search_938A2XZUsueOMeta?.name ?? "search-search___ph",
    path: _91_46_46_46search_938A2XZUsueOMeta?.path ?? "/ph/search/:search(.*)*",
    meta: _91_46_46_46search_938A2XZUsueOMeta || {},
    alias: _91_46_46_46search_938A2XZUsueOMeta?.alias || [],
    redirect: _91_46_46_46search_938A2XZUsueOMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/search/[...search].vue").then(m => m.default || m)
  },
  {
    name: settingsvjiEC3KAb9Meta?.name ?? "settings___us",
    path: settingsvjiEC3KAb9Meta?.path ?? "/settings",
    meta: settingsvjiEC3KAb9Meta || {},
    alias: settingsvjiEC3KAb9Meta?.alias || [],
    redirect: settingsvjiEC3KAb9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsvjiEC3KAb9Meta?.name ?? "settings___gb",
    path: settingsvjiEC3KAb9Meta?.path ?? "/gb/settings",
    meta: settingsvjiEC3KAb9Meta || {},
    alias: settingsvjiEC3KAb9Meta?.alias || [],
    redirect: settingsvjiEC3KAb9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsvjiEC3KAb9Meta?.name ?? "settings___sg",
    path: settingsvjiEC3KAb9Meta?.path ?? "/sg/settings",
    meta: settingsvjiEC3KAb9Meta || {},
    alias: settingsvjiEC3KAb9Meta?.alias || [],
    redirect: settingsvjiEC3KAb9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsvjiEC3KAb9Meta?.name ?? "settings___my",
    path: settingsvjiEC3KAb9Meta?.path ?? "/my/settings",
    meta: settingsvjiEC3KAb9Meta || {},
    alias: settingsvjiEC3KAb9Meta?.alias || [],
    redirect: settingsvjiEC3KAb9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsvjiEC3KAb9Meta?.name ?? "settings___id",
    path: settingsvjiEC3KAb9Meta?.path ?? "/id/settings",
    meta: settingsvjiEC3KAb9Meta || {},
    alias: settingsvjiEC3KAb9Meta?.alias || [],
    redirect: settingsvjiEC3KAb9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsvjiEC3KAb9Meta?.name ?? "settings___ph",
    path: settingsvjiEC3KAb9Meta?.path ?? "/ph/settings",
    meta: settingsvjiEC3KAb9Meta || {},
    alias: settingsvjiEC3KAb9Meta?.alias || [],
    redirect: settingsvjiEC3KAb9Meta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceOijF35eM4TMeta?.name ?? "terms-of-service___us",
    path: terms_45of_45serviceOijF35eM4TMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45serviceOijF35eM4TMeta || {},
    alias: terms_45of_45serviceOijF35eM4TMeta?.alias || [],
    redirect: terms_45of_45serviceOijF35eM4TMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceOijF35eM4TMeta?.name ?? "terms-of-service___gb",
    path: terms_45of_45serviceOijF35eM4TMeta?.path ?? "/gb/terms-of-service",
    meta: terms_45of_45serviceOijF35eM4TMeta || {},
    alias: terms_45of_45serviceOijF35eM4TMeta?.alias || [],
    redirect: terms_45of_45serviceOijF35eM4TMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceOijF35eM4TMeta?.name ?? "terms-of-service___sg",
    path: terms_45of_45serviceOijF35eM4TMeta?.path ?? "/sg/terms-of-service",
    meta: terms_45of_45serviceOijF35eM4TMeta || {},
    alias: terms_45of_45serviceOijF35eM4TMeta?.alias || [],
    redirect: terms_45of_45serviceOijF35eM4TMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceOijF35eM4TMeta?.name ?? "terms-of-service___my",
    path: terms_45of_45serviceOijF35eM4TMeta?.path ?? "/my/terms-of-service",
    meta: terms_45of_45serviceOijF35eM4TMeta || {},
    alias: terms_45of_45serviceOijF35eM4TMeta?.alias || [],
    redirect: terms_45of_45serviceOijF35eM4TMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceOijF35eM4TMeta?.name ?? "terms-of-service___id",
    path: terms_45of_45serviceOijF35eM4TMeta?.path ?? "/id/terms-of-service",
    meta: terms_45of_45serviceOijF35eM4TMeta || {},
    alias: terms_45of_45serviceOijF35eM4TMeta?.alias || [],
    redirect: terms_45of_45serviceOijF35eM4TMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceOijF35eM4TMeta?.name ?? "terms-of-service___ph",
    path: terms_45of_45serviceOijF35eM4TMeta?.path ?? "/ph/terms-of-service",
    meta: terms_45of_45serviceOijF35eM4TMeta || {},
    alias: terms_45of_45serviceOijF35eM4TMeta?.alias || [],
    redirect: terms_45of_45serviceOijF35eM4TMeta?.redirect || undefined,
    component: () => import("/builds/epicareer/epicareer-website/pages/terms-of-service.vue").then(m => m.default || m)
  }
]