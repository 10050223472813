<script lang="ts" setup>
import clone from 'just-clone'
import locales from '~/assets/data/locales.json'

const emit = defineEmits(['update:searchbar'])
const route = useRoute()
const router = useRouter()
const { $config } = useNuxtApp()
const keyword = ref('')
const isLangModalOpen = useCookie('isLangModalOpen', { path: '/', domain: $config.public.COOKIE_DOMAIN })
const errorVal = ref()
const mobilePopupShown = ref(false)
const langOpt = ref(clone(locales))
langOpt.value.unshift({ label: 'Select Region', icon: '', region: '', lang: '', hrefLang: '' })
const selectedRegion = langOpt.value.find(item => item.region === useGetSubdomain()) || {} as any
const searchQuery = ref()
const isSuggestLoading = ref(false)

onMounted(async () => {
  await nextTick()
  if (route.params?.search) {
    const [firstPart, secondPart] = route.params?.search.toString().replace(/\bjobs\b/, '').split(/\bin\b/).map(str => str.trim())
    keyword.value = !isEmpty(firstPart) ? useTextCapitalize(useTextToParam(firstPart.slice(0, -1), true)) : ''
    searchQuery.value = !isEmpty(firstPart) ? useTextCapitalize(useTextToParam(firstPart.slice(0, -1), true)) : ''
  }
  else {
    keyword.value = route.query?.q ? useTextCapitalize(useTextToParam(route.query?.q.toString(), true)) : ''
  }
})

watch(() => route.query, (newVal, oldVal) => {
  if (newVal.st !== oldVal.st || newVal.q !== oldVal.q) {
    if (route.fullPath.includes('/search')) {
      keyword.value = !isEmpty(newVal.q) ? useTextCapitalize(useTextToParam(newVal.q.toString(), true)) : ''
      searchQuery.value = !isEmpty(newVal.q) ? useTextCapitalize(useTextToParam(newVal.q.toString(), true)) : ''
    }
    else {
      keyword.value = ''
      searchQuery.value = ''
    }
  }
})

watch(() => route.params, (newVal) => {
  if (!isEmpty(newVal.search) && !isEmpty(newVal?.search[0])) {
    const [firstPart, secondPart] = newVal?.search[0].replace(/\bjobs\b/, '').split(/\bin\b/).map(str => str.trim())
    keyword.value = !isEmpty(firstPart) ? useTextCapitalize(useTextToParam(firstPart.slice(0, -1), true)) : ''
    searchQuery.value = !isEmpty(firstPart) ? useTextCapitalize(useTextToParam(firstPart.slice(0, -1), true)) : ''
  }
})

async function onSearch() {
  // need to use useGetSubdomain because region will have US as default value when user close the modal
  if (isEmpty(useGetSubdomain()) && !searchQuery.value) { errorVal.value = 'Enter keywords and choose region to continue' }
  else if (!isEmpty(useGetSubdomain()) && !searchQuery.value) { errorVal.value = 'Please insert keywords' }
  else if (isEmpty(useGetSubdomain()) && searchQuery.value) { errorVal.value = 'Please choose region first' }
  else {
    errorVal.value = ''
    keyword.value = searchQuery.value
    router.replace(route.query?.st === 'companies' ? `/search?st=companies&q=${encodeURIComponent(keyword.value.toLowerCase())}&location=all&industry=all&page=1` : `/search?st=jobs&q=${encodeURIComponent(keyword.value.toLowerCase())}&locations=all&employment=all&work_type=all&date_posted=all&sort=relevance`)
    openSearch(false)
  }
}

function openSearch(val: boolean) {
  errorVal.value = null
  mobilePopupShown.value = val
  emit('update:searchbar', val)
}
</script>

<template>
  <div class="lg:relative">
    <!-- ERROR -->
    <div class="top-17 left-0 lg:top-16 absolute flex w-full z-1 lg:z-0">
      <div v-if="errorVal" class="border border-rose-500 bg-rose-50 rounded-md px-2 py-2.5 mx-auto text-sm font-medium text-gray-600 flex items-center">
        <div class="flex-shrink-0 text-rose-500 mr-2">
          <Icon name="material-symbols:warning" class="w-5 h-5" />
        </div>
        {{ errorVal }}
        <div class="flex-shrink-0 text-rose-500 ml-2">
          <Icon name="mdi:close" class="w-5 h-5 cursor-pointer text-gray-600" @click="errorVal = ''" />
        </div>
      </div>
    </div>

    <!-- DESKTOP LAYOUT -->
    <div class="items-center hidden lg:flex">
      <div :class="`${isEmpty(useGetSubdomain()) ? 'cursor-pointer' : ''} flex items-center px-6 py-2 h-10 bg-[#F3F4F6] rounded-md w-full`">
        <!-- ACTIVE FLAG -->
        <div v-if="useGetSubdomain()" class="flex-shrink-0 flex items-center mr-4 pr-4 border-r-1 cursor-pointer" @click="isLangModalOpen = 'true'">
          <Icon :name="selectedRegion.icon" class="w-5 h-5" />
        </div>
        <div v-if="!isEmpty(useGetSubdomain())" class="w-full">
          <BaseOrganismsSuggestion :search-query="searchQuery" :keyword="keyword" @update:keyword="newVal => searchQuery = newVal" @run:on-search="onSearch()" @update:search-loading="newVal => isSuggestLoading = newVal" />
        </div>
        <!-- INPUT TEXT -->
        <div v-else class="text-gray-400 w-full h-full text-sm" @click="isEmpty(useGetSubdomain()) ? isLangModalOpen = 'true' : ''">
          Jobs or company...
        </div>

        <!-- ICON INDICATOR -->
        <div class="flex-shrink-0 flex items-center text-gray-400">
          <Icon
            :name="(!isEmpty(searchQuery) && !isSuggestLoading) ? 'mdi:close-circle-outline' : ((isEmpty(searchQuery) && !isSuggestLoading) ? 'mdi:magnify' : 'eos-icons:loading')" :class="`w-6 h-6 ${(!isEmpty(searchQuery) && !isSuggestLoading
            ) ? 'cursor-pointer text-gray-600' : ''}`" @click="(!isEmpty(searchQuery) && !isSuggestLoading) ? searchQuery = '' : ''"
          />
        </div>
      </div>
    </div>

    <!-- MOBILE LAYOUT -->
    <div class="flex items-center block lg:hidden">
      <!-- DISPLAY -->
      <div class="flex items-center px-3 py-2.5 h-10 bg-[#F3F4F6] rounded-md w-full relative" @click="isEmpty(useGetSubdomain()) ? isLangModalOpen = 'true' : openSearch(true)">
        <!-- ACTIVE FLAG -->
        <div v-if="useGetSubdomain()" class="flex-shrink-0 flex items-center mr-4 pr-4 border-r-1 cursor-pointer">
          <Icon :name="selectedRegion.icon" class="w-5 h-5" />
        </div>

        <!-- DISPLAY SEARCH -->
        <div :class="`${keyword ? 'text-gray-900' : 'text-gray-400 '} w-full ml-1 text-sm cursor-pointer truncate block`">
          {{ keyword || 'Jobs or company...' }}
        </div>

        <!-- ICON INDICATOR -->
        <div class="flex-shrink-0 flex items-center text-gray-400">
          <Icon :name="`${keyword && !isSuggestLoading ? 'mdi:close-circle-outline' : (!keyword && !isSuggestLoading ? 'mdi:magnify' : '')}`" :class="`${keyword && !isSuggestLoading ? 'cursor-pointer' : ''} w-5 h-5`" @click="keyword && !isSuggestLoading ? keyword = '' : ''" />
        </div>
      </div>
      <!-- INPUT  -->
      <div v-if="mobilePopupShown" class="flex absolute left-0 top-0 px-4 py-3 z-1 bg-white h-full w-full">
        <div class="flex-shrink-0 flex m-auto">
          <Icon name="material-symbols:arrow-back-ios" class="w-6 h-6 cursor-pointer" @click="openSearch(false)" />
        </div>
        <div class="flex items-center pl-4 pr-2 py-2.5 h-10 bg-[#F3F4F6] rounded-md w-full">
          <!-- ACTIVE FLAG -->
          <div v-if="useGetSubdomain()" class="flex-shrink-0 flex items-center mr-4 pr-4 border-r-1 cursor-pointer" @click="isLangModalOpen = 'true'">
            <Icon :name="selectedRegion.icon" class="w-5 h-5" />
          </div>

          <!-- INPUT TEXT -->
          <!-- <input v-model="keyword" type="text" placeholder="Jobs or company..." class="placeholder-gray-400 text-gray-900 w-full h-full border-0 focus:ring-0 focus:outline-none bg-transparent text-sm p-0" @keyup.enter="onSearch()"> -->
          <BaseOrganismsSuggestion :search-query="searchQuery" :keyword="keyword" @update:keyword="newVal => searchQuery = newVal" @run:on-search="onSearch()" @update:search-loading="newVal => isSuggestLoading = newVal" />
          <!-- ICON INDICATOR -->
          <div class="flex-shrink-0 flex items-center text-gray-400 ml-auto">
            <!-- <Icon :name="`${keyword && (!isLoading || !isSuggestLoading) ? 'mdi:close-circle-outline' : (!keyword && (!isLoading || !isSuggestLoading) ? 'mdi:magnify' : ((isLoading || isSuggestLoading) ? 'eos-icons:loading' : ''))}`" class="w-6 h-6 cursor-pointer text-gray-600" @click="keyword && (!isLoading || !isSuggestLoading) ? keyword = '' : ''" /> -->
            <Icon :name="(!isEmpty(searchQuery) && !isSuggestLoading) ? 'mdi:close-circle-outline' : ((isEmpty(searchQuery) && !isSuggestLoading) ? 'mdi:magnify' : 'eos-icons:loading')" :class="`w-6 h-6 ${(!isEmpty(searchQuery) && !isSuggestLoading) ? 'cursor-pointer text-gray-600' : ''}`" @click="(!isEmpty(searchQuery) && !isSuggestLoading) ? searchQuery = '' : ''" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="mobilePopupShown" class="bg-gray-900 w-screen h-screen absolute left-0 bg-opacity-50" style="backdrop-filter: blur(15px)" />
  </div>
</template>
