<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
interface Props {
  class?: string
}
const props = withDefaults(defineProps<Props>(), {
  class: 'pt-10 md:pt-16',
})

const openFooter = ref(null)
const config = useRuntimeConfig()
const javascriptEnabled = computed(() => process.client)
const isClient = process.client
const isLoading = ref(true)
onMounted(() => {
  isLoading.value = false
})
</script>

<template>
  <footer :class="props.class" aria-labelledby="footer-heading">
    <div class="bg-white border-t-2 border-gray-200">
      <p id="footer-heading" class="sr-only">
        Footer
      </p>
      <div class="w-full flex max-w-6xl py-4 lg:py-10 px-5 lg:px-10 xl:px-0 mx-auto">
        <div v-if="(!isLoading && javascriptEnabled) || (isLoading && !javascriptEnabled)" class="md:flex w-full md:space-x-5 lg:space-x-15 xl:space-x-25 lg:col-span-8">
          <!-- directories -->
          <div v-if="!isEmpty(useGetSubdomain())">
            <Disclosure :default-open="false">
              <DisclosureButton class="flex items-center mb-3.5 w-full" @click="openFooter = openFooter === 'directory' ? null : 'directory'">
                <label class="font-bold text-gray-700 block">Directory</label>
                <span v-if="isClient" class="ml-auto flex-1 text-right cursor-pointer block md:hidden">
                  <Icon v-if="openFooter !== 'directory'" name="heroicons:plus" class="w-5 h-5" />
                  <Icon v-else name="heroicons:x-mark" class="w-5 h-5" />
                </span>
              </DisclosureButton>
              <DisclosurePanel as="div" :class="`${((openFooter === 'directory' && javascriptEnabled) || !javascriptEnabled) ? 'block' : 'hidden md:block'} space-y-3.5 mb-6`" static>
                <ul class="space-y-3.5">
                  <li>
                    <NuxtLink href="/jobs">
                      Jobs
                    </NuxtLink>
                  </li>
                  <li>
                    <NuxtLink href="/companies/all">
                      Companies
                    </NuxtLink>
                  </li>
                  <li v-if="useGetSubdomain() === 'id' || useGetSubdomain() === 'my' || useGetSubdomain() === 'ph' || useGetSubdomain() === 'sg'">
                    <NuxtLink href="/search">
                      Search Jobs
                    </NuxtLink>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>

          <!-- our product -->
          <div v-if="!isEmpty(useGetSubdomain()) && (useGetSubdomain() !== 'cn' && useGetSubdomain() !== 'hk')">
            <Disclosure>
              <DisclosureButton class="flex items-center mb-3.5 w-full" @click="openFooter = openFooter === 'product' ? null : 'product'">
                <label class="font-bold text-gray-700 block">Our product</label>
                <span v-if="isClient" class="ml-auto flex-1 text-right cursor-pointer block md:hidden">
                  <Icon v-if="openFooter !== 'product'" name="heroicons:plus" class="w-5 h-5" />
                  <Icon v-else name="heroicons:x-mark" class="w-5 h-5" />
                </span>
              </DisclosureButton>
              <DisclosurePanel as="div" :class="`${((openFooter === 'product' && javascriptEnabled) || !javascriptEnabled) ? 'block' : 'hidden md:block'} space-y-3.5 mb-6`" static>
                <ul class="space-y-3.5">
                  <li>
                    <a href="https://epicareer.com" target="_blank">For Jobseekers</a>
                  </li>
                  <li>
                    <a href="https://epicareer.com/employer" target="_blank">For Employer</a>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>

          <!-- follow us -->
          <div>
            <Disclosure>
              <DisclosureButton class="flex items-center mb-3.5 w-full" @click="openFooter = openFooter === 'follow' ? null : 'follow'">
                <label class="font-bold text-gray-700 block">Follow us</label>
                <span v-if="isClient" class="ml-auto flex-1 text-right cursor-pointer block md:hidden">
                  <Icon v-if="openFooter !== 'follow'" name="heroicons:plus" class="w-5 h-5" />
                  <Icon v-else name="heroicons:x-mark" class="w-5 h-5" />
                </span>
              </DisclosureButton>
              <DisclosurePanel as="div" :class="`${((openFooter === 'follow' && javascriptEnabled) || !javascriptEnabled) ? 'block' : 'hidden md:block'} space-y-3.5 mb-6`" static>
                <ul class="space-y-3.5">
                  <li>
                    <a href="https://www.instagram.com/epicareer" target="_blank">Instagram</a>
                  </li>
                  <li>
                    <a href="https://twitter.com/epicareer" target="_blank">Twitter</a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/epicareer" target="_blank">Facebook</a>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>

          <!-- our partners, but only on SG and MY -->
          <div v-if="useGetSubdomain() === 'sg' || useGetSubdomain() === 'my'">
            <Disclosure>
              <DisclosureButton class="flex items-center mb-3.5 w-full" @click="openFooter = openFooter === 'partner' ? null : 'partner'">
                <label class="font-bold text-gray-700 block">Our Partners</label>
                <span v-if="isClient" class="ml-auto flex-1 text-right cursor-pointer block md:hidden">
                  <Icon v-if="openFooter !== 'partner'" name="heroicons:plus" class="w-5 h-5" />
                  <Icon v-else name="heroicons:x-mark" class="w-5 h-5" />
                </span>
              </DisclosureButton>
              <DisclosurePanel as="div" :class="`${((openFooter === 'partner' && javascriptEnabled) || !javascriptEnabled) ? 'block' : 'hidden md:block'} space-y-3.5 mb-6`" static>
                <ul class="space-y-3.5">
                  <li>
                    <a href="https://www.ajobthing.com" target="_blank">AJobThing</a>
                  </li>
                  <li>
                    <a href="https://www.maukerja.my" target="_blank">Maukerja</a>
                  </li>
                  <li>
                    <a href="https://www.ricebowl.my" target="_blank">Ricebowl</a>
                  </li>
                </ul>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
      <div class="border-t-1 border-gray-200 flex">
        <div class="md:flex space-y-5 md:space-y-0 w-full py-4 lg:py-6 px-5 lg:px-10 xl:px-0 max-w-6xl mx-auto">
          <div class="min-w-64 w-full w-[85%] lg:flex space-y-5 lg:space-y-0">
            <span class="my-auto">Copyright © 2023 Epicareer Sdn. Bhd.</span>
            <div class="mx-auto leading-9" style="line-break: anywhere;">
              <span class="border-r-1 mr-4 pr-4 whitespace-nowrap">
                <NuxtLink href="/privacy-policy">Privacy Policy</NuxtLink>
              </span>
              <span class="border-r-1 mr-4 pr-4 whitespace-nowrap">
                <NuxtLink href="/terms-of-service">Terms of Service</NuxtLink>
              </span>
              <span class="border-r-1 mr-4 pr-4 whitespace-nowrap">
                <NuxtLink href="/faq">FAQ</NuxtLink>
              </span>
              <span class="border-r-1 mr-4 pr-4 whitespace-nowrap">
                <NuxtLink href="/contact">Contact Us</NuxtLink>
              </span>
              <span class="mr-4 pr-4 whitespace-nowrap">
                <NuxtLink href="/about">About Us</NuxtLink>
              </span>
            </div>
          </div>
          <!-- Language -->
          <BaseOrganismsLanguage :class="`${isClient ? 'md:ml-auto h-4 min-w-48 lg:h-full w-full md:w-auto' : ''}`" />
        </div>
      </div>
    </div>
  </footer>
</template>
