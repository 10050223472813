export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$router.options.scrollBehavior = async (to, from, savedPosition) => {
    const findEl = async (hash: string, x = 0) => {
      return (
        document.querySelector(hash)
        || new Promise((resolve) => {
          if (x > 0)
            return resolve(document.querySelector('#app'))

          setTimeout(() => {
            resolve(findEl(hash, 1))
          }, 300)
        })
      )
    }

    if (to.hash) {
      const LIMIT = 3
      let TRYCOUNT = 0
      let el: any = null
      do {
        el = await findEl(to.hash)
        if (el)
          break
        TRYCOUNT++
      }
      while (TRYCOUNT < LIMIT)

      if (!el)
        return { left: 0, top: 0, behaviour: 'smooth' }
      else if ('scrollBehavior' in document.documentElement.style)
        return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
      else
        return window.scrollTo(0, el.offsetTop)
    }
    else if (savedPosition) {
      return savedPosition
    }
    else {
      return { left: 0, top: 0, behaviour: 'smooth' }
    }
  }
})
