<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    styles: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    size: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    typeEl() {
      if (this.tag === 'a')
        return ''
      else return this.type
    },
    disabledClass() {
      let value = ''
      this.disabled ? (value = 'cursor-not-allowed opacity-50') : (value = '')

      if ((this.color === 'primary' || !this.color) && this.disabled)
        value += ' !bg-gray-400 !opacity-100'

      return value
    },
    roundedClass() {
      let value = ''
      this.rounded ? (value = '!rounded-full') : (value = '')
      return value
    },
    sizeClass() {
      let value = ''

      switch (this.size) {
        case 'xs':
          value = 'px-2.5 py-1.5 text-xs rounded'
          break
        case 'sm':
          value = 'px-3 py-2 text-sm leading-4 rounded-md'
          break
        case 'md':
          value = 'px-4 py-2 text-sm rounded-md min-h-11'
          break
        case 'lg':
          value = 'px-4 py-2 text-base rounded-md min-h-12'
          break
        case 'xl':
          value = 'px-6 py-3 text-base rounded-md min-h-13'
          break
        default:
          value = 'px-4 py-2 text-base rounded-md'
      }

      return value
    },
    colorClass() {
      let value = ''
      switch (this.color) {
        case 'primary':
          if (this.outline)
            value = 'btn-primary-outline '
          value += 'btn-primary'
          break
        case 'secondary':
          value += 'btn-secondary'
          break
        case 'success':
          if (this.outline)
            value = 'btn-success-outline '
          value += 'btn-success'
          break
        case 'danger':
          if (this.outline)
            value = 'btn-danger-outline '
          value += 'btn-danger'
          break
        case 'warning':
          if (this.outline)
            value = 'btn-warning-outline '
          value += 'btn-warning'
          break
        case 'info':
          if (this.outline)
            value = 'btn-info-outline '
          value += 'btn-info'
          break
        case 'white':
          value += 'btn-white'
          break
        case 'dark':
          if (this.outline)
            value = 'btn-dark-outline '
          value += 'btn-dark'
          break
        case 'gray':
          if (this.outline)
            value = 'btn-gray-outline '
          value += 'btn-grey'
          break
        case 'link':
          value += 'btn-link'
          break
        default:
          if (this.outline)
            value = 'btn-primary-outline '
          value += 'btn-primary'
      }

      return value
    },
  },
}
</script>

<template>
  <NuxtLink
    v-if="to" :style="styles" :to="to" :tag="tag"
    class="inline-flex items-center shadow-sm border focus:outline-none text-center justify-center"
    :class="[classes, disabledClass, sizeClass, colorClass, roundedClass]"
  >
    {{ text }} <slot />
  </NuxtLink>
  <component
    :is="tag" v-else :style="styles" :type="typeEl" :disabled="disabled"
    class="inline-flex items-center shadow-sm border focus:outline-none text-center justify-center"
    :class="[classes, disabledClass, sizeClass, colorClass, roundedClass]"
  >
    {{ text }} <slot />
  </component>
</template>
