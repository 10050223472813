import { defineStore } from 'pinia'
import { useUser } from '~/store/user'
import type { JobItem, JobPayload, JobPayloadExclude, SearchItem } from '~/models/Job'

interface JobState {
  jobs: JobItem[]
  similarSearch: SearchItem[]
  payload: JobPayload
  isError: any
  isFetching: boolean
  isStatus: number | null
}

const defaultPayload = {
  location: {
    address: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    longitude: null,
    latitude: null,
    radius: 50,
  },
  locationDisabled: false,
  experiences: [],
  skills: [],
  types: ['full_time', 'part_time', 'internship', 'freelance'],
  langs: [],
  salary: {
    min: null,
    max: null,
    currency: '',
    country: '',
  },
  remote: ['onsite', 'remote'],
  experienceYears: null,
  from: 0,
  size: 10,
} as JobPayload

export const useJobs = defineStore('jobs', {
  state: (): JobState => ({
    jobs: [] as JobItem[],
    similarSearch: [] as SearchItem[],
    payload: defaultPayload,
    isError: null,
    isFetching: false,
    isStatus: null,
  }),
  actions: {
    async fetchJob(payload: JobPayload) {
      return await useJobApi('/jobs/recommend', {
        initialCache: false,
        method: 'POST',
        body: payload,
        key: 'fetch_job',
      })
    },
    async searchJob(payload: {}, keyVal: string) {
      // hide key because when activated, pressing back button on search page cannot run this useSearchApi
      return await useSearchApi('/jobs/search', {
        initialCache: false,
        method: 'POST',
        body: payload,
        // key: keyVal,
      })
    },
    resetJob() {
      this.jobs = []
    },
    resetJobPayload() {
      this.payload = defaultPayload
    },
    updateJob(response: JobItem[]) {
      this.jobs = response
    },
    updateSimilarSearch(response: SearchItem[]) {
      this.similarSearch = response
    },
    updatePayloadFromUserData(exclude?: JobPayloadExclude) {
      const user = useUser()

      function jobTitle(exp: { jobTitle: string }) {
        return exp.jobTitle
      }

      function getDuration(end, start) {
        const startDate = new Date(start)
        const endDate = end ? new Date(end) : new Date()
        const totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth())
        if (totalMonths <= 12) { return 1 }
        else {
          const years = Math.floor(totalMonths / 12)
          const remainingMonths = totalMonths % 12
          if (remainingMonths > 6)
            return years + 1
          else return years
        }
      }

      const location = {
        address: user.parsed?.address || null,
        city: user.parsed?.city || null,
        state: user.parsed?.state || null,
        zipcode: user.parsed?.postCode || null,
        country: user.parsed?.country || null,
      }

      const skills = user.parsed?.skills && [].concat(user.parsed.skills)
      const experiences = user.parsed?.workExperiences ? [].concat(user.parsed.workExperiences.map(jobTitle)) : []
      const langs = [].concat(user.parsed.languages)

      // filter empty value
      const filteredExperiences = experiences.filter(Boolean)

      // check exclude
      if (!exclude?.location)
        this.payload.location = { ...this.payload.location, ...location }
      if (!exclude?.skills)
        this.payload.skills = skills
      if (!exclude?.experiences)
        this.payload.experiences = filteredExperiences
      if (!exclude?.jobExperiences)
        this.payload.jobExperiences = user.parsed?.workExperiences ? user.parsed?.workExperiences.map(item => ({ title: item.jobTitle, duration: `${getDuration(item.end, item.start)} year` })) : []
      if (!exclude?.education)
        this.payload.education = user.parsed.educations ? { level: user.parsed.educations[0]?.degree, major: user.parsed.educations[0]?.degreeType } : { level: null, major: null }
      if (!exclude?.langs)
        this.payload.langs = langs

      // calculate experienceYears
      const durations = user.parsed.workExperiences ? user.parsed.workExperiences.map(item => item.duration).reduce((item: number, result: number) => item + result, 0) : 0
      const durationYears = Math.round(durations / 12)
      this.payload.experienceYears = durationYears || 0

      // add preference
      this.payload.remote = !isEmpty(user.parsed.preference.works) ? user.parsed.preference.works : ['remote', 'onsite']
      this.payload.salary.country = user.parsed.preference.salary.country
      this.payload.salary.currency = user.parsed.preference.salary.currency
      this.payload.salary.min = user.parsed.preference.salary.amount
      this.payload.locationDisabled = user.parsed.preference.isAnywhere
    },
    updateDefaultCurrency(country: any) {
      this.payload.salary.currency = country.currency
      this.payload.salary.country = country['alpha-2']
    },
  },
  persist: {
    key: 'jobs',
    storage: process.client ? window.localStorage : null,
    paths: ['jobs', 'payload'],
  },
})
