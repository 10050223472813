<script lang="ts" setup>
</script>

<template>
  <div class="text-gray-900">
    <div class="flex bg-[#EFF6FF] p-4 text-sm font-semibold">
      <div id="alert-container" class="mx-auto flex items-center">
        <div class="flex-shrink-0 flex items-center">
          <Icon name="material-symbols:info" class="w-5 h-5 mr-2 text-[#5843E5]" />
        </div>
        <span id="title" class="mr-4">Epicareer Might not Working Properly</span>
        <a class="button ml-auto" href="#learn-more">
          <div class="px-4 py-2 rounded-md border-1 border-[#5843E5] bg-white text-[#5843E5] whitespace-nowrap text-xs font-bold">Learn More</div>
        </a>
      </div>
    </div>

    <div id="learn-more" class="overlay absolute top-0 bottom-0 left-0 right-0 h-screen bg-black bg-opacity-25 grid" style="backdrop-filter: blur(10px)">
      <a class="absolute cursor-pointer" href="/" />
      <div class="popup relative m-auto py-7 px-6 bg-white rounded-lg">
        <div class="flex items-center">
          <span class="font-bold text-base" data-nosnippet>Please Turn on your JavaScript</span>
          <a class="close ml-auto flex-shrink-0" href="/">
            <Icon name="mdi:close" class="w-6 h-6 ml-auto" />
          </a>
        </div>
        <hr class="mt-4 mb-6">
        <div class="space-y-6 grid">
          <p class="text-sm text-gray-600" data-nosnippet>
            Epicareer might not working properly because your browser javascript is turned off.
          </p>
          <div>
            <div class="flex items-center mb-2">
              <Icon name="logos:javascript" class="w-6 h-6 mr-2" />
              <p class="text-xl font-bold" data-nosnippet>
                What is JavaScript?
              </p>
            </div>
            <div class="text-sm text-gray-600" data-nosnippet>
              JavaScript is like the magic behind making websites interactive. Basically, it makes Epicareer more enjoyable and engaging.
            </div>
          </div>
          <div>
            <div class="mb-2 text-xl font-bold" data-nosnippet>
              How do i turn this on?
            </div>
            <div class="text-sm text-gray-600 mb-2" data-nosnippet>
              Find tutorial for your browser below :
            </div>
            <div class="overflow-auto grid" style="max-height: 360px;">
              <!-- SAFARI -->
              <div class="mb-2">
                <input id="title1" class="hidden" type="checkbox">
                <label for="title1" class="bg-gray-50 rounded-lg p-4 text-base cursor-pointer flex items-center" data-nosnippet>
                  <Icon name="logos:safari" class="w-6 h-6 mr-4" />
                  Safari
                </label>

                <div class="content p-4">
                  <ol class="text-gray-600 list-decimal ml-4">
                    <li data-nosnippet>
                      Make sure the webpage is open.
                    </li>
                    <li data-nosnippet>
                      In the Safari app on your Mac, choose Safari > Preferences, then click Security.
                    </li>
                    <li data-nosnippet>
                      Make sure the Enable JavaScript checkbox is selected.
                    </li>
                    <li data-nosnippet>
                      Click Websites.
                    </li>
                    <li data-nosnippet>
                      On the left, click Content Blockers.
                    </li>
                    <li data-nosnippet>
                      Make sure Off is chosen in the pop-up menu next to the website.
                    </li>
                    <li data-nosnippet>
                      On the left, click Pop-up Windows.
                    </li>
                    <li data-nosnippet>
                      Make sure Allow is chosen in the pop-up menu next to the website.
                    </li>
                  </ol>
                  <div class="mt-4">
                    <a href="https://support.apple.com/en-mn/guide/safari/ibrw1032/16.0/mac/11.0" target="_blank" class="text-[#5843E5]" data-nosnippet>Visit Site here</a>
                  </div>
                </div>
              </div>

              <!-- GOOGLE -->
              <div class="mb-2">
                <input id="title2" class="hidden" type="checkbox">
                <label for="title2" class="bg-gray-50 rounded-lg p-4 text-base cursor-pointer flex items-center" data-nosnippet>
                  <Icon name="logos:google-icon" class="w-6 h-6 mr-4" />
                  Google Chrome
                </label>

                <div class="content p-4">
                  <ol class="text-gray-600 list-decimal ml-4">
                    <li data-nosnippet>
                      Open Chrome on your computer.
                    </li>
                    <li data-nosnippet>
                      Click. then Settings.
                    </li>
                    <li data-nosnippet>
                      Click Privacy and Security.
                    </li>
                    <li data-nosnippet>
                      Click Site settings.
                    </li>
                    <li data-nosnippet>
                      Click JavaScript.
                    </li>
                    <li data-nosnippet>
                      Select Sites can use Javascript.
                    </li>
                  </ol>
                  <div class="mt-4">
                    <a href="https://support.google.com/admanager/answer/12654?hl=en" target="_blank" class="text-[#5843E5]" data-nosnippet>Visit Site here</a>
                  </div>
                </div>
              </div>

              <!-- MOZILLA -->
              <div class="mb-2">
                <input id="title3" class="hidden" type="checkbox">
                <label for="title3" class="bg-gray-50 rounded-lg p-4 text-base cursor-pointer flex items-center" data-nosnippet>
                  <Icon name="logos:firefox" class="w-6 h-6 mr-4" />
                  Mozilla Firefox
                </label>

                <div class="content p-4">
                  <ol class="text-gray-600 list-decimal ml-4">
                    <li data-nosnippet>
                      If you're running Windows OS, in the Firefox window, click Tools > Options.
                    </li>
                    <li data-nosnippet>
                      Tip: If you're running Mac OS, click the Firefox drop-down list > Preferences.
                    </li>
                    <li data-nosnippet>
                      On the Content tab, click the Enable JavaScript check box.
                    </li>
                  </ol>
                  <div class="mt-4">
                    <a href="https://support.mozilla.org/en-US/questions/967344" target="_blank" class="text-[#5843E5]" data-nosnippet>Visit Site here</a>
                  </div>
                </div>
              </div>

              <!-- MICROSOFT -->
              <div class="mb-2">
                <input id="title4" class="hidden" type="checkbox">
                <label for="title4" class="bg-gray-50 rounded-lg p-4 text-base cursor-pointer flex items-center" data-nosnippet>
                  <Icon name="logos:microsoft-edge" class="w-6 h-6 mr-4" />
                  Microsoft Edge
                </label>

                <div class="content p-4">
                  <ol class="text-gray-600 list-decimal ml-4">
                    <li data-nosnippet>
                      Click the "Settings and more" icon at the top right of the Edge browser window.
                    </li>
                    <li data-nosnippet>
                      Click Settings.
                    </li>
                    <li data-nosnippet>
                      From the Settings menu, click Cookies and site permissions.
                    </li>
                    <li data-nosnippet>
                      Scroll down and click on JavaScript.
                    </li>
                    <li data-nosnippet>
                      In the JavaScript window, toggle the slider to "on" or "allowed."
                    </li>
                    <li data-nosnippet>
                      Close the Settings tab.
                    </li>
                    <li data-nosnippet>
                      Refresh the page.
                    </li>
                  </ol>
                  <div class="mt-4">
                    <a href="https://support.microsoft.com/en-gb/office/enable-javascript-7bb9ee74-6a9e-4dd1-babf-b0a1bb136361" target="_blank" class="text-[#5843E5]" data-nosnippet>Visit Site here</a>
                  </div>
                </div>
              </div>

              <!-- OPERA -->
              <div class="mb-2">
                <input id="title1" class="hidden" type="checkbox">
                <label for="title1" class="bg-gray-50 rounded-lg p-4 text-base cursor-pointer flex items-center" data-nosnippet>
                  <Icon name="logos:opera" class="w-6 h-6 mr-4" />
                  Opera
                </label>

                <div class="content p-4">
                  <ol class="text-gray-600 list-decimal ml-4">
                    <li data-nosnippet>
                      Go to Settings.
                    </li>
                    <li data-nosnippet>
                      Click Advanced in the left sidebar, and click Privacy & security.
                    </li>
                    <li data-nosnippet>
                      Under Privacy and security, click Site settings.
                    </li>
                    <li data-nosnippet>
                      Click JavaScript.
                    </li>
                    <li data-nosnippet>
                      At the top, turn on or off Allowed (recommended).
                    </li>
                  </ol>
                  <div class="mt-4">
                    <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank" class="text-[#5843E5]" data-nosnippet>Visit Site here</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center text-gray-500 mx-auto" data-nosnippet>
            Any Question?
            <a href="/contact" target="_blank" class="text-[#059669] font-bold ml-1" data-nosnippet>Contact us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.overlay {
  transition: opacity 200ms;
  visibility: hidden;
  z-index: 100;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  width: 100%;
  max-width: 476px;
}

.popup .close {
  transition: all 200ms;
  text-decoration: none;
}

.popup .close:hover {
  opacity: 1;
}

label {
    display: block;
    transition: ease .5s;
}

input + label + .content {
    display: none;
}

input:checked + label + .content {
    display: block;
}

@media only screen and (max-width: 464px) {
  #title {
    width: 183px;
    margin-right: 0;
  }
  #alert-container{
    width: 100%;
  }
}
</style>
