import revive_payload_client_4sVQNw7RlN from "/builds/epicareer/epicareer-website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/epicareer/epicareer-website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/epicareer/epicareer-website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/builds/epicareer/epicareer-website/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import plugin_vue3_A0OWXRrUgq from "/builds/epicareer/epicareer-website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/epicareer/epicareer-website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/epicareer/epicareer-website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_CICqBBANNd from "/builds/epicareer/epicareer-website/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_t2GMTTFnMT from "/builds/epicareer/epicareer-website/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import mount_plugin_client_GyWXdaUM8H from "/builds/epicareer/epicareer-website/node_modules/nuxt-delay-hydration/dist/runtime/mount-plugin.client.mjs";
import composition_sLxaNGmlSL from "/builds/epicareer/epicareer-website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/builds/epicareer/epicareer-website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/epicareer/epicareer-website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import global_error_ZtxEZ1VnAo from "/builds/epicareer/epicareer-website/plugins/global-error.ts";
import numeral_2uTwz2q7of from "/builds/epicareer/epicareer-website/plugins/numeral.ts";
import pinia_persistedstate_client_LNIOmdnZsk from "/builds/epicareer/epicareer-website/plugins/pinia-persistedstate.client.ts";
import redirect_catch_client_L1RVpPoUzl from "/builds/epicareer/epicareer-website/plugins/redirect-catch.client.ts";
import router_scrollBehaviour_c9NVjBhrnl from "/builds/epicareer/epicareer-website/plugins/router.scrollBehaviour.ts";
import vue_awesome_paginate_rvI9ZlY63V from "/builds/epicareer/epicareer-website/plugins/vue-awesome-paginate.ts";
import vue_gtag_client_CYt6toK02h from "/builds/epicareer/epicareer-website/plugins/vue-gtag.client.ts";
import vue_gtm_client_stBjLl0OeM from "/builds/epicareer/epicareer-website/plugins/vue-gtm.client.ts";
import vue_toastification_client_F29MfCWaUJ from "/builds/epicareer/epicareer-website/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  strapi_CICqBBANNd,
  plugin_t2GMTTFnMT,
  mount_plugin_client_GyWXdaUM8H,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  global_error_ZtxEZ1VnAo,
  numeral_2uTwz2q7of,
  pinia_persistedstate_client_LNIOmdnZsk,
  redirect_catch_client_L1RVpPoUzl,
  router_scrollBehaviour_c9NVjBhrnl,
  vue_awesome_paginate_rvI9ZlY63V,
  vue_gtag_client_CYt6toK02h,
  vue_gtm_client_stBjLl0OeM,
  vue_toastification_client_F29MfCWaUJ
]