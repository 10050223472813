import numeral from 'numeral'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      numeral: (value?: string | number) => numeral(value),
      numeralFormat: (value?: string | number, format = '0,0[.]00'): string => numeral(value).format(format),
    },
  }
})
