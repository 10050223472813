<script setup>
const props = defineProps({
  error: Object,
})

const config = useRuntimeConfig()
const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
const route = useRoute()
function reloadPage() {
  window.location.reload(true)
}
</script>

<template>
  <div class="min-h-screen relative text-sm text-gray-900 flex flex-col bg-gray-50">
    <BaseOrganismsMainNavbar />
    <div v-if="!isEmpty(token) && route.path.includes('/dashboard')" class="w-full max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto pt-4 lg:pt-10">
      <BaseOrganismsUserMenu />
    </div>
    <div :class="`${isEmpty(token) ? 'mt-20' : ''} py-6 lg:py-20 mb-40 <lg:px-4 flex w-full max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto bg-white justify-center`">
      <div class="text-center w-full max-w-540px m-auto">
        <div v-if="error?.statusCode.toString() === '404'">
          <p class="font-bold text-2xl">
            This Page is Missing
          </p>
          <img class="mx-auto w-full max-w-300px " src="/images/404.png" alt="Error 404 Illustration">
          <p class="text-sm text-gray-600 mb-2">
            Please check the URL or go back to the homepage
          </p>
          <p class="text-xs font-semibold mb-6">
            Error code : {{ error?.statusCode.toString() }}
          </p>
          <BaseAtomsButton size="sm" class="font-bold <sm:w-full sm:min-w-[325px] px-4 py-2.5 mb-4" to="/">
            Go to Epicareer home
          </BaseAtomsButton>
        </div>
        <div v-else>
          <p class="font-bold text-2xl">
            It’s not you, It’s us
          </p>
          <img class="mx-auto w-full max-w-300px " src="/images/5xx.png" alt="Error 5xx Illustration">
          <p class="text-sm text-gray-600 mb-2">
            Our server has an error. We're fixing it. Please reload or try again later.
          </p>
          <p class="text-xs font-semibold mb-6">
            Error code : {{ error?.statusCode.toString() }}
          </p>
          <BaseAtomsButton size="sm" class="font-bold <sm:w-full sm:min-w-[325px] px-4 py-2.5 mb-4" @click="reloadPage()">
            Reload Page
          </BaseAtomsButton>
          <p class="text-gray-600">
            <NuxtLink to="/contact" class="text-[#5843E5] font-bold mr-1">
              Contact us
            </NuxtLink>
            If this still happen
          </p>
        </div>
      </div>
    </div>
    <BaseOrganismsMainFooter class="mt-auto" />
  </div>
</template>
