import { defineStore } from 'pinia'
import type { Country, Education, UserFile, UserParsed, UserRank, UserSettings, UserSource, ValidationError } from '~/models/User'

interface UserState {
  token: string | null
  validation: ValidationError[]
  totalError: number
  file: UserFile
  parsed: UserParsed
  uuid: string | null
  tnc: boolean
  communication: boolean
  ip?: string | null
  country?: Country
  source?: UserSource | null
  rank: UserRank
  settings?: UserSettings
}

export const useUser = defineStore('user', {
  state: (): UserState => ({
    validation: [] as ValidationError[],
    totalError: 0,
    uuid: null,
    token: null,
    file: {} as UserFile,
    parsed: {} as UserParsed,
    tnc: false,
    communication: true,
    source: null,
    ip: null,
    country: {} as Country,
    rank: {},
    settings: {} as UserSettings,
  }),

  getters: {
    allowExport(state) {
      const userExp = state.parsed?.workExperiences
      const userEdu = state.parsed?.educations ? state.parsed?.educations[0] : [] as Education
      const userExpCompleteness = userExp
        ? userExp.map((exp) => {
          return !!(exp.employer && exp.jobTitle && exp.start)
        })
        : [true]
      const userEduCompleteness = !!(userEdu?.institution && userEdu?.degree && userEdu?.degreeType && userEdu?.start)
      const userBasicCompleteness = !!(state.parsed?.name && state.parsed?.email && state.parsed?.phone && (state.parsed?.city || state.parsed?.country))
      return ![!userExpCompleteness.includes(false), userEduCompleteness, userBasicCompleteness].includes(false)
    },
  },

  actions: {
    updateUser(response: any) {
      if ('token' in response)
        this.token = response.token
      if ('file' in response)
        this.file = response.file
      if ('parsed' in response)
        this.parsed = response.parsed
      if ('source' in response)
        this.source = response.source
    },

    updateUUID(id: string) {
      this.uuid = id
    },

    updateTNC(state: boolean) {
      this.tnc = state
    },

    updateToken(val: string) {
      this.token = val
    },

    updateUserConfig(config: any) {
      const { ip, country } = config
      this.ip = ip
      this.country = country
    },

    updateValidationError(errorList: ValidationError[], expScope: null | string = null) {
      if (expScope === null) {
        this.validation = errorList
      }
      else {
        const expIdx = this.validation.map(e => e.scope).indexOf(expScope)
        if (expIdx > -1)
          this.validation.splice(expIdx, 1)
      }

      if (this.validation.length > 0)
        this.totalError = 0
      else
        this.totalError = -1
      this.validation.forEach(data => (this.totalError = this.totalError + data.error.length))
    },

    async getRank() {
      const { data, pending, refresh, error }
        = await useResumeApi<{ data: UserRank }>('/users/rank', { lazy: true, initialCache: false, useApiKey: true })

      watch(data, (newData) => {
        this.rank = newData?.data || {}
      }, { immediate: true })

      return { data, pending, refresh, error }
    },

    updateRankUpgraded(isUpgraded: boolean) {
      this.rank.isUpgraded = isUpgraded
    },

    updateUserSetting(settings: UserSettings) {
      this.settings = settings
    },
  },

  persist: {
    key: 'user',
    storage: process.client ? window.localStorage : null,
    paths: ['file', 'tnc', 'source'],
  },
})
