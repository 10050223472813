export function useGetSubdomain() {
  const { $config } = useNuxtApp()
  let url: string | undefined

  // Check if it's running on the server
  if (process.server) {
    const event = useRequestEvent()

    // Ensure that event and req objects are available
    if (event && event.node && event.node.req && event.node.req.url) {
      // Prepend a hostname to make it a valid URL since req.url might be a relative path
      url = `http://${event.node.req.headers.host}${event.node.req.url}`
    }
  }
  else if (process.client) {
    // On the client-side, use the full URL from the browser
    url = window.location.href
  }

  // If URL is undefined, return an empty string or handle it as needed
  if (!url)
    return '' // Handle this case accordingly in your app

  // Now process the URL
  const subdomain = url.replace('https://', '').replace('http://', '').split('.')
  const region = ref('')

  // Handle environment-specific logic for subdomain extraction
  if ($config.public.ENV === 'development' || $config.public.ENV === 'staging')
    region.value = subdomain[0]?.split('-')[1] || '' // Handle potential undefined
  else
    region.value = subdomain.length === 2 ? '' : subdomain[0]

  return region.value
}

