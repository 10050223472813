<script lang="ts" setup>
const route = useRoute()
const menus = ref([
  { url: '/dashboard', id: 'profile', label_mobile: 'Dashboard', label_desktop: 'Dashboard', icon: 'mdi:home-outline' },
  { url: '/dashboard/enhance-resume', id: 'enhance', label_mobile: 'Resume', label_desktop: 'Enhance Resume', icon: 'ic:outline-auto-fix-high' },
  { url: '/dashboard/job-recommendation/fetch', id: 'recommendation', label_mobile: 'Jobs', label_desktop: 'Job Recommendation', icon: 'mdi:work-outline' },
  { url: '/dashboard/salary-report', id: 'salary', label_mobile: 'Report', label_desktop: 'Salary Report', icon: 'ic:outline-monetization-on' },
])
</script>

<template>
  <div class="bg-white p-4 grid grid-cols-4 md:flex items-center space-x-2 rounded-md mb-4 lg:mb-6">
    <NuxtLink v-for="(item, idx) in menus" :key="idx" :to="item.url" :class="`${(item.url.includes(route.path.split('/')[2]) || item.url === '/dashboard' && route.path === '/dashboard') ? 'text-[#5843E5] font-bold bg-[#ECE9FC]' : 'text-gray-600'} rounded-md py-2 px-1 md:py-2 md:px-6 text-center md:flex items-center`">
      <div class="flex-shrink-0 md:mr-2">
        <Icon :name="item.icon" class="w-6 h-6" />
      </div>
      <span class="hidden md:block">{{ item.label_desktop }}</span>
      <span class="block md:hidden text-[10px] mt-1">{{ item.label_mobile }}</span>
    </NuxtLink>
  </div>
</template>
