<script lang="ts" setup>
import { v4 as uuidv4 } from 'uuid'
import { useUser } from '~/store/user'
import { useJobs } from '~/store/job'
import type { UserParsed, UserResume } from '~/models/User'
import { useStatistic } from '~/store/statistic'

const config = useRuntimeConfig()
const statistic = useStatistic()
const user = useUser()
const route = useRoute()
const region = useCookie('region', { path: '/', domain: config.public.COOKIE_DOMAIN })
const uuid = useCookie('uuid', { path: '/' })
const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
const redirect = useCookie('redirect', { path: '/', maxAge: 3600, domain: config.public.COOKIE_DOMAIN })
const job = useJobs()
const headers = useRequestHeaders()

// verify token
if (process.server) {
  const { data } = await useFetch <{ status: number }>('/api/verify-auth', { key: 'verify-auth', headers })
  if (data.value?.status !== 200)
    token.value = null
}

// update user
if (token.value) {
  user.updateToken(token.value)
  const { data: userData, error } = await useCompanyApi<{ data: { user: UserParsed } }>('/talent/user', { key: 'fetch_user', useAuth: true, useAPIV2: true })
  const resp = userData.value as { data: { user: UserParsed } }
  if (userData.value?.data?.user) {
    if (resp.data?.user?.email.includes('deleted-['))
      navigateTo('/logout')
    const resume = resp.data?.user?.resume || [] as UserResume[]
    const sortedResume = resume.sort((a, b) => {
      return new Date(b.dateCreated).valueOf() - new Date(a.dateCreated).valueOf()
    })
    const latestResume = (sortedResume && sortedResume.length > 0 && sortedResume[0]) || {} as UserResume

    user.updateUser({
      parsed: userData.value.data.user,
      file: {
        date: latestResume?.dateCreated || '',
        fileName: latestResume?.fileDisplay || '',
        fileDisplay: latestResume?.fileDisplay || '',
        path: latestResume?.file || '',
      },
      source: 'resume file',
    })
    job.updatePayloadFromUserData()

    // init job count
    await statistic.fetchJobCount()

    // cannot put on middleware, it keep getting undefined
    if (route.path !== '/onboard' && !userData.value.data.user.phone) {
      navigateTo({ path: '/onboard', query: { scope: 'basic' } })
    }
    else if (route.path !== '/onboard' && !userData.value.data.user.educations) {
      navigateTo({ path: '/onboard', query: { scope: 'education' } })
    }
    else if (route.path !== '/onboard' && (userData.value.data.user.hasExperience === undefined || (!userData.value.data.user.workExperiences && userData.value.data.user.hasExperience))) {
      navigateTo({ path: '/onboard', query: { scope: 'experience' } })
    }
    else if (redirect.value && userData.value.data.user.phone && userData.value.data.user.educations && (userData.value.data.user.hasExperience !== undefined || (userData.value.data.user.workExperiences && userData.value.data.user.hasExperience))) {
      navigateTo(redirect.value, { external: true })
      redirect.value = null
    }
  }
}
else {
  user.$reset()
  token.value = null
  redirect.value = null
}

// check uuid
if (!uuid.value) {
  const newUUID = uuidv4()
  uuid.value = newUUID
  user.updateUUID(newUUID)
}
else {
  user.updateUUID(uuid.value)
}

// get ip/country info
const { data: countryData } = await useFetch('/api/getIPGeolocation', {
  headers,
})

if (countryData.value) {
  if (countryData.value?.country) {
    user.updateUserConfig(countryData.value)
    job.updateDefaultCurrency(countryData.value.country)
  }
}

onBeforeMount(async () => {
  // set default region on first session
  region.value = useGetSubdomain()
})

if (useGetSubdomain() === 'cn') {
  useHead({
    meta: [
      { name: 'baidu-site-verification', content: 'codeva-V9ilEJdDUG' },
    ],
  })
}
</script>

<template>
  <main>
    <NuxtLayout>
      <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#818cf8 0%,#818cf8 50%,#4f46e5 100%)" />
      <NuxtPage />
    </NuxtLayout>
  </main>
</template>
