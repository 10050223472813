const useJobApi = async <T>(url: string | (() => string), options?: object) => {
  const config = useRuntimeConfig()
  const controller = new AbortController()
  const signal = controller.signal
  const timeout = setTimeout(() => controller.abort(), config.public.API_TIMEOUT)
  const fetchOptions: { useApi2?: boolean; useApi5?: boolean } = options

  return useFetch<T>(url, {
    baseURL: config.public.JOBAPI_BASE_URL,
    signal,
    async onRequest({ options }) {
      options.headers = new Headers(options.headers)
      if (fetchOptions.useApi2)
        options.headers.append('x-api-version', '2')
      if (fetchOptions.useApi5)
        options.headers.append('x-api-version', '5')

      options.headers.append('Content-Type', 'application/json')
      options.headers.append('Authorization', `Basic ${config.public.JOBAPI_KEY}`)
    },
    async onRequestError({ error }) {
      clearTimeout(timeout)
      throw error
    },
    async onResponse() {
      clearTimeout(timeout)
    },
    async onResponseError({ request, response }) {
      clearTimeout(timeout)
      console.error('[fetch response error]', request, response.status, response.body)
    },
    ...options,
  })
}

export default useJobApi
