<script lang="ts" setup>
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue'

interface Props {
  keyword: string
  searchQuery: string | undefined
}

interface Suggestion {
  jobs: SuggestionItem[]
  companies: SuggestionItem[]
}

interface SuggestionItem {
  title: string
  logo?: string
  rawTitle?: string
  slug?: string
}

const props = defineProps<Props>()
const emit = defineEmits(['update:keyword', 'update:searchLoading', 'run:onSearch'])
const router = useRouter()
const searchQuery = ref(props.keyword)
const keyword = ref(props.keyword)
const isSuggestLoading = ref(false)
const isFocus = ref(false)
const emptyList = ref<Suggestion>()
const suggestionList = ref<Suggestion>()
const suggestionDebounce = useDebounceFn(() => {
  if (searchQuery.value.length === 1 || searchQuery.value.length === 2)
    return

  emit('update:keyword', searchQuery.value)

  // need to comment this because with updating keyword to parent, watch props.searchQuery already running runSuggestion
  // runSuggestion()
}, 500)

async function getSuggestion() {
  keyword.value = searchQuery.value
  suggestionList.value = null
  isFocus.value = true
  await suggestionDebounce()
}

watch(() => props.keyword, (newVal) => {
  // watch if url changes
  keyword.value = newVal
  searchQuery.value = newVal
})

watch(() => props.searchQuery, (newVal) => {
  // watch if searchquery change when user click x on search bar
  keyword.value = newVal
  searchQuery.value = newVal
  suggestionList.value = null
  runSuggestion()
})

async function runSuggestion() {
  emit('update:searchLoading', true)
  isSuggestLoading.value = true

  if (isEmpty(searchQuery.value) && !isEmpty(emptyList.value)) { suggestionList.value = emptyList.value }
  else {
    const { data, error } = await useSearchApi('/suggest', {
      key: 'get_search_suggestion',
      method: 'POST',
      initialCache: false,
      body: {
        term: searchQuery.value || null,
        country: useGetSubdomain(),
      },
    })

    const resp = data.value as Suggestion
    if (!isEmpty(resp) && !error.value && (!isEmpty(resp.jobs) || !isEmpty(resp.companies))) {
      if (isEmpty(emptyList.value)) {
        emptyList.value = splitData(resp)
        suggestionList.value = splitData(resp)
      }
      else {
        suggestionList.value = splitData(resp)
      }
    }
  }

  isSuggestLoading.value = false
  emit('update:searchLoading', false)
}

function splitData(data: Suggestion) {
  const newJob = ref(data.jobs)
  const newCompany = ref(data.companies)

  if (data.companies.length >= 3 && data.jobs.length >= 3) {
    newJob.value = data.jobs.slice(0, 3)
    newCompany.value = data.companies.slice(0, 3)
  }
  else if (data.companies.length < 3 && data.jobs.length >= 3) { newJob.value = data.jobs.slice(0, 6 - data.companies.length) }
  else if (data.jobs.length < 3 && data.companies.length >= 3) { newCompany.value = data.companies.slice(0, 6 - data.jobs.length) }

  return {
    jobs: !isEmpty(newJob.value)
      ? newJob.value.map(job => ({
        ...job,
        title: highlightMatch(job.title),
        rawTitle: job.title,
      }))
      : [],
    companies: !isEmpty(newCompany.value)
      ? newCompany.value.map(companies => ({
        ...companies,
        title: highlightMatch(companies.title),
        rawTitle: companies.title,
      }))
      : [],
  }
}

function highlightMatch(text: string): string {
  if (!searchQuery.value)
    return text

  const regex = new RegExp(`(${searchQuery.value})`, 'gi')
  return text.replace(regex, '<strong>$1</strong>')
}

async function onBlur() {
  await useSleep(500)
  searchQuery.value = null
  suggestionList.value = null
  isFocus.value = false
}

async function onEnter(val: SuggestionItem) {
  if (!isEmpty(val?.slug)) {
    keyword.value = null
    searchQuery.value = null
    emit('update:keyword', null)
    router.push(`/companies/${val.slug}`)
  }
  else {
    emit('update:keyword', typeof keyword.value !== 'string' ? searchQuery.value : keyword.value)
    emit('run:onSearch')
    suggestionList.value = null
  }
  isFocus.value = false
}

async function onInputClick() {
  isFocus.value = true
  searchQuery.value = !isEmpty(searchQuery.value) ? searchQuery.value : keyword.value || null
  runSuggestion()
}
</script>

<template>
  <div class="w-full">
    <Combobox v-model="keyword" @update:model-value="newVal => onEnter(newVal)">
      <ComboboxInput
        ref="inputField"
        class="text-gray-900 w-full h-full border-0 focus:ring-0 focus:outline-none bg-transparent text-sm p-0 placeholder-gray-400"
        placeholder="Jobs or company..."
        @click="onInputClick"
        @input="getSuggestion"
        @blur="onBlur()"
        @change="(searchQuery = $event.target.value)"
      />
      <ComboboxOptions v-if="isFocus" static class="absolute top-full w-full py-2 z-1 <lg:left-0 lg:-ml-20 shadow-md bg-white">
        <ComboboxOption v-if="isEmpty(searchQuery) && !isEmpty(suggestionList?.jobs)">
          <li class="px-4 pt-4 py-2 cursor-pointer text-gray-700 bg-white text-xs font-bold">
            Jobs You Might Like
          </li>
        </ComboboxOption>
        <ComboboxOption v-if="!isEmpty(searchQuery) && !suggestionList?.jobs.some(item => item.rawTitle.toLowerCase() === searchQuery.toLowerCase())" v-slot="{ active }" as="template">
          <li class="px-4 cursor-pointer hover:bg-gray-100 bg-white" data-headlessui-state="active selected" :class="{ 'text-primary-600 bg-gray-100': active, 'text-gray-700': !active }" :value="searchQuery">
            <div class="py-4 flex items-center font-bold">
              {{ searchQuery }}
              <Icon name="mdi:arrow-top-right" class="ml-auto w-5 h-5 text-gray-400" />
            </div>
            <hr v-if="!isEmpty(suggestionList?.companies) && !isEmpty(suggestionList?.jobs)">
          </li>
        </ComboboxOption>
        <div v-if="!isEmpty(suggestionList?.jobs)" as="template">
          <ComboboxOption v-for="(val, idx) in suggestionList?.jobs" :key="idx" v-slot="{ active }" :value="val.rawTitle">
            <li class="px-4 cursor-pointer hover:bg-gray-100 bg-white" :class="{ 'text-primary-600 bg-gray-100': active, 'text-gray-700': !active }">
              <div class="py-4 flex items-center">
                <div v-html="val.title" />
                <Icon name="mdi:arrow-top-right" class="ml-auto w-5 h-5 text-gray-400" />
              </div>
              <hr v-if="(isEmpty(suggestionList?.companies) && idx < suggestionList?.jobs.length - 1) || !isEmpty(suggestionList?.companies)">
            </li>
          </ComboboxOption>
        </div>
        <ComboboxOption v-if="!isEmpty(suggestionList?.companies)">
          <li class="px-4 pt-4 py-2 cursor-pointer text-gray-700 bg-white text-xs font-bold">
            {{ isEmpty(searchQuery) ? 'Companies You Might Like' : 'Companies' }}
          </li>
        </ComboboxOption>
        <div v-if="!isEmpty(suggestionList?.companies)" as="template">
          <ComboboxOption v-for="(val, idx) in suggestionList?.companies" :key="idx" v-slot="{ active }" :value="val">
            <li class="px-4 cursor-pointer hover:bg-gray-100 bg-white" :class="{ 'text-primary-600 bg-gray-100': active, 'text-gray-700': !active }">
              <div class="py-4 flex items-center">
                <div class="flex-shrink-0 mr-2">
                  <img v-if="val.logo" :src="val.logo" class="object-contain w-5 h-5">
                  <Icon v-else name="ic:twotone-business" class="mx-auto w-5 h-5 text-[#5843E5]" />
                </div>
                <div v-html="val.title" />
              </div>
              <hr v-if="idx < suggestionList?.companies.length - 1">
            </li>
          </ComboboxOption>
        </div>
      </ComboboxOptions>
    </Combobox>
  </div>
</template>
