const useCompanyApi = async <T>(url: string | (() => string), options?: object) => {
  const config = useRuntimeConfig()
  const controller = new AbortController()
  const signal = controller.signal
  const timeout = setTimeout(() => controller.abort(), config.public.API_TIMEOUT)
  const token = useCookie('token', { path: '/', domain: config.public.COOKIE_DOMAIN })
  const fetchOptions: { useAuth?: boolean; useAPIV2?: boolean; useAPIV5?: boolean; contentFile?: boolean } = options

  return useFetch<T>(url, {
    baseURL: config.public.COMPANYAPI_BASE_URL,
    signal,
    async onRequest({ options }) {
      options.headers = new Headers(options.headers)
      if (fetchOptions.useAuth)
        options.headers.append('Authorization', `Bearer ${token.value}`)

      if (fetchOptions.useAPIV2)
        options.headers.append('x-api-version', '2')

      if (fetchOptions.useAPIV5)
        options.headers.append('x-api-version', '5')

      if (!fetchOptions.contentFile)
        options.headers.append('Content-Type', 'application/json')
    },
    async onRequestError({ error }) {
      clearTimeout(timeout)
      throw error
    },
    async onResponse() {
      clearTimeout(timeout)
    },
    async onResponseError({ request, response }) {
      clearTimeout(timeout)
      console.error('[fetch response error]', request, response.status, response.body)
    },
    ...options,
  })
}

export default useCompanyApi
