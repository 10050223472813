import { useUser } from '~/store/user'

interface customParam {
  [k: string]: string
}

const useSetTracking = async <T>(eventName: string, eventModel?: customParam) => {
  const { $config } = useNuxtApp()
  const user = useUser()
  const token = useCookie('token', { path: '/', domain: $config.public.COOKIE_DOMAIN })

  const totalYears = !isEmpty(user.parsed?.workExperiences)
    ? user.parsed?.workExperiences.reduce((total, job) => {
      const startDate = new Date(job.start)
      const endDate = (job.isCurrent || !job.end) ? new Date() : new Date(job.end)
      const diffInMs = endDate - startDate
      const years = diffInMs / (1000 * 60 * 60 * 24 * 365)
      return total + years
    }, 0)
    : 0

  const userProperty = {
    user_ids: user.parsed?.id,
    country: user.parsed?.country,
    total_years_of_exp: totalYears.toFixed(1),
    login_status: token.value ? 'logged_in' : 'public',
  }

  // Only push to dataLayer if running in client (browser)
  if (process.client) {
    const additionalProperty = { event: 'user_event', custom_event: eventName }
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      ...userProperty,
      ...eventModel,
      ...additionalProperty,
    })
  }
}

export default useSetTracking
