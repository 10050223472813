import VueGtag, { trackRouter } from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: config.public.GTAG_ID,
    },
    disableScriptLoader: false,
    useDebugger: false,
  })

  trackRouter(router, {
    template(to) {
      const title = to.meta?.title || to.name
      return {
        page_title: title,
        page_path: to.path,
      }
    },
  })
})
