
import { updateAppConfig } from '#app'
import { defuFn } from '/builds/epicareer/epicareer-website/node_modules/defu/dist/defu.mjs'

const inlineConfig = {}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/epicareer/epicareer-website/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
